import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/env.config.json";
import { CardTypes } from "../../../../enums";
import { getCC } from "../../../../features/account/accountSlice";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";
import { manageCard } from "../../../../features/strapi/manageCardSlice";
import { getCache } from "../../../../utils/commonFunctions";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { ButtonCust, Divider, ManageCard } from "../../../widgets";
import { AutoPayConsentModal } from "../../../widgets/ManageCards/AutoPayConsentModal";
// import styles from './T1_AccountPlanDetails.module.scss';

function AccountPlanSummaryTemplate(props: any) {
  const {
    handleBack,
    currentPackPlan,
    taxesAndFees,
    newPackPlan,
    ChangePlanDetails,
    differenceAmount,
    autoPayCard,
    manageCardFg,
    content,
    showCardChangeModal,
    setShowCardChangeModal,
    showCardChangeModalHandle,
    confirmPlanChangeHandler,
    handleUpgradeModal,
    showUpgradeModal,
    closeUpgradeModal,
    upgradeNow,
    setUpgradeNow,
    upgradeLater,
    setUpgradeLater,
    downgradeNow,
    setDowngradeNow,
    downgradeLater,
    setDowngradeLater,
    setPlanChangedLoader,
    planChangedLoader,
    autoPaymentData,
    upgradeFlow,
  } = props;
  const {
    chng_btn,
    visa,
    discover,
    master_card,
    jcb,
    american_ex,
    num_mask,
    upgrade_late_note,
    pricing,
  } = content;
  const {
    taxes,
    current_plan,
    new_plan,
    upgrade_now_note,
    upgrade_now_txt1,
    upgrade_now_txt2,
    upgrade_now,
    downgrade_later,
    add_card,
    downgrade_note,
    downgrade_txt,
    change_card,
    cc_mask,
    plan_change_summ,
    no_card_upg_text1,
    no_card_upg_text2,
  } = content?.changeplanData;

  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { autoPaymentDetails } = useAppSelector(
    (state: any) => state.strapi.billing
  );
  const { type4Tax } = useAppSelector((state: any) => state.account);
  const { getCustomer } = useAppSelector((state: any) => state.checkout);

  const { planDiffAmount, currentPlanCost, newPlanCost } = ChangePlanDetails;
  const { currentPlanTaxes, newPlanTaxes, diffAmountTaxes } = taxesAndFees;
  const balanceAmountToday = Number(
    Number(planDiffAmount) + Number(diffAmountTaxes)
  ).toFixed(2);
  const { getCCDetails, group, errorGetCCDetails } = useAppSelector(
    (state: any) => state?.account || {}
  );
  const [manageCardDetails, setManageCardDetails] = useState<any>(null);
  const [noCardFound, setNoCardFound] = useState<any>(false);
  const [showAutoPayConsentModal, setShowAutoPayConsentModal] = useState(false);
  const [autoPayConsent, setAutoPayConsent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cardWithAutoPay, setCardWithAutoPay] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [cardToShow, setCardToShow] = useState<any>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useAppDispatch();
  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = customerDetails ? JSON.parse(customerDetails) : null;
  const changePlanPaymentData = [
    {
      text: `Difference amount`,
      price: planDiffAmount,
    },
    {
      text: `Taxes`,
      price: diffAmountTaxes,
    },
    {
      text: `Balance due today`,
      price: balanceAmountToday,
    },
  ];

  const filteredGroupAccountLine = group?.data?.filter(
    (data: any) => data?.status === 2
  );
  const { manageCardContent } = useAppSelector(
    (state: any) => state.strapi.manageCard
  );
  useEffect(() => {
    setCardToShow(autoPayCard);
  }, []);
  useEffect(() => {
    if (getCCDetails) {
      setNoCardFound(false);
      let noAutoPayFound: boolean = false;
      getCCDetails?.some((el: any) => {
        if (el.isAutoPay) {
          setCardWithAutoPay(true);
          setCardToShow(el);
          noAutoPayFound = true;
        } else {
          setCardWithAutoPay(false);
        }
      });
      if (!noAutoPayFound) {
        let latestCard: any = getCCDetails[0];
        getCCDetails?.forEach((el: any) => {
          if (new Date(el.createdDate) > new Date(latestCard.createdDate)) {
            latestCard = el;
          }
        });
        setCardToShow(latestCard);
      }
      getCCDetails.map((card: any) => {
        if (card.isAutoPay) {
          // autoPayCard(card);
          return null;
        } else {
          return null;
        }
      });
    }
  }, [getCCDetails]);

  useEffect(() => {
    if (
      errorGetCCDetails &&
      errorGetCCDetails?.status === "FAILURE" &&
      errorGetCCDetails?.resCode === 40135
    ) {
      setNoCardFound(true);
    }
  }, [errorGetCCDetails]);

  useEffect(() => {
    dispatch(getCC({ customerId: customerDetails?.id }));
    getCache("ManageCards", manageCard, setManageCardDetails, dispatch);
    dispatch(
      GetCustomer({
        username: encodeURIComponent(customerDetails?.emailId),
      })
    );
  }, []);

  const PaymentHandler = () => {};
  const addNewCardHandler = () => {
    localStorage.setItem("addNewCard", "true");
    setIsLoading(true);
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  return (
    <>
      <form
        ref={formRef}
        method="post"
        name="addCardForm"
        id="addCardForm"
        action={Config.ADD_CARD_ADDRESS}
      >
        <input
          type="hidden"
          name="emailId"
          value={getCustomer?.emailId ? getCustomer.emailId : ""}
        />
        <input
          type="hidden"
          name="redirectUrl"
          value={window.location.origin}
        />
        {Config.IS_AUTOPAY_ENABLED && (
          <>
            <input type="hidden" name="saveCard" value="true" />
            <input
              type="hidden"
              name="autoPay"
              value={String(autoPayConsent)}
            />
            <input
              type="hidden"
              // name="defaultCard"
              value={String(autoPayConsent)}
            />
          </>
        )}
      </form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: "5rem",
          mt: { xs: "10px", lg: "5px" },
          background: "var(--lightgrey)",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "23px",
            height: "23px",
            position: "absolute",
            left: "3rem",
            cursor: "pointer",
          }}
          onClick={() => {
            handleBack("accountPage");
            setDowngradeNow(null);
            setUpgradeNow(null);
            setUpgradeLater(null);
            setDowngradeLater(null);
          }}
        >
          <ArrowBackIcon sx={{ width: "23px" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            mt: { xs: "10px", sm: "0" },
          }}
        >
          <Box
            component="img"
            src={content?.title_icon?.data?.attributes?.url}
            alt={content?.title_icon?.data?.attributes?.alternativeText}
            sx={{ width: "30px" }}
          />
          <Typography
            component={"span"}
            sx={{
              color: "var(--title-color_1)",
              fontSize: { xs: "1.5em", md: "30px" },
              fontStyle: "normal",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {plan_change_summ}
          </Typography>
        </Box>
        <Box sx={{ mt: "1rem", mb: "1rem" }}>
          <Divider />
        </Box>
        <KeyboardBackspaceIcon
          sx={{
            display: { xs: "block", sm: "none" },
            position: "absolute",
            left: "1.4rem",
            top: "6.1rem",
            color: "var(--white)",
            cursor: "pointer",
          }}
          onClick={() => {
            handleBack("changePlan");
            setDowngradeNow(null);
            setUpgradeNow(null);
            setUpgradeLater(null);
            setDowngradeLater(null);
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          width="100%"
          sx={{
            borderRadius: "8px",
            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
            maxWidth: { xs: "350px", sm: "500px", md: "550px", lg: "600px" },
            padding: { xs: "15px", sm: "30px" },
            mx: { xs: "1rem", sm: "0px" },
            background: "var(--white)",
          }}
        >
          {/* Current Plan */}
          <Box sx={{ width: "100%", pb: "3px", px: { xs: "0px", sm: "50px" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                my: "25px",
              }}
              gap={"15px"}
            ></Box>
            <Typography
              sx={{
                color: "var(--lite_black_3)",
                fontFamily: "var(--font_family_Bold)",
                fontSize: { xs: "1em", md: "16px" },
                my: "5px",
                textAlign: "left",
                width: "100%",
              }}
            >
              {current_plan}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1em", md: "16px" },
                  textAlign: "left",
                }}
              >
                {currentPackPlan?.name.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1.125em", md: "16px" },
                }}
              >
                {GetCurrencyFixedToTwo(currentPlanCost)}
                {pricing}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1em", md: "16px" },
                  textAlign: "left",
                }}
              >
                {taxes}
              </Typography>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1.125em", md: "16px" },
                }}
              >
                {GetCurrencyFixedToTwo(currentPlanTaxes)}
              </Typography>
            </Box>
          </Box>

          {/* <Divider
            orientation="horizontal"
            flexItem
            sx={{
              mx: { xs: "0px", sm: "50px" },
              borderBottomWidth: "2px",
              borderColor: "var(--navy)",
              opacity: "0.1",
            }}
          /> */}
          {/* New Plan */}
          <Box
            sx={{ width: "100%", pt: "1rem", px: { xs: "0px", sm: "50px" } }}
          >
            <Typography
              sx={{
                color: "var(--lite_black_3)",
                fontFamily: "var(--font_family_Bold)",
                fontSize: { xs: "1em", md: "16px" },
                my: "5px",
                textAlign: "left",
                width: "100%",
              }}
            >
              {new_plan}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1em", md: "16px" },
                  textAlign: "left",
                }}
              >
                {newPackPlan?.name.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1.125em", md: "16px" },
                }}
              >
                {GetCurrencyFixedToTwo(newPlanCost)}
                {pricing}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1em", md: "16px" },
                  textAlign: "left",
                }}
              >
                {taxes}
              </Typography>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "1.125em", md: "16px" },
                }}
              >
                {GetCurrencyFixedToTwo(newPlanTaxes)}
              </Typography>
            </Box>
          </Box>

          {/* Difference Amount */}
          {(upgradeNow || upgradeLater || upgradeFlow) && (
            <Box
              sx={{
                width: "95%",
                px: { xs: "auto", sm: "30px" },
              }}
            >
              {!downgradeLater ? (
                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid var(--light-silver_color_2)",
                    borderRadius: "6px",
                    background: "var(--white)",
                    mt: "20px",
                    px: { xs: "1rem", sm: "23px" },
                    py: { xs: "1rem", sm: "15px" },
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {changePlanPaymentData?.map((data: any, idx: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          pb: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              idx === 2
                                ? "var(--text_color_9)"
                                : "var(--lite_black_3)",
                            fontFamily:
                              idx === 2
                                ? "var(--font_family_Bold)"
                                : "var(--font_family_Semibold)",
                            fontSize: { xs: "1em", md: "16px" },
                            textAlign: "left",
                          }}
                        >
                          {data.text}
                        </Typography>
                        <Typography
                          sx={{
                            color:
                              idx === 2
                                ? "var(--text_color_9)"
                                : "var(--lite_black_3)",
                            fontFamily:
                              idx === 2
                                ? "var(--font_family_Bold)"
                                : "var(--font_family_Semibold)",
                            fontSize: { xs: "1.125em", md: "16px" },
                          }}
                        >
                          {GetCurrencyFixedToTwo(Number(data.price))}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              ) : null}

              {cardToShow?.ccNumberMask && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    // className={styles.image_text_container}
                    py="15px"
                    px="15px"
                    my="20px"
                    borderRadius="4px"
                    sx={{
                      border: "1px solid var(--light-silver_color_2)",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={
                          cardToShow?.type?.toUpperCase() === CardTypes?.VISA
                            ? visa?.data?.attributes?.url
                            : cardToShow?.type?.toUpperCase() ===
                              CardTypes?.MASTERCARD
                            ? master_card?.data?.attributes?.url
                            : cardToShow?.type?.toUpperCase() === CardTypes?.JCB
                            ? jcb?.data?.attributes?.url
                            : cardToShow?.type?.toUpperCase() ===
                                CardTypes?.AMERICANEXPRESS ||
                              cardToShow?.type?.toUpperCase() ===
                                CardTypes?.AMEX
                            ? american_ex?.data?.attributes?.url
                            : discover?.data?.attributes?.url
                        }
                        alt={
                          cardToShow && cardToShow.type === CardTypes.VISA
                            ? visa?.data?.attributes?.alternativeText
                            : american_ex?.data?.attributes?.alternativeText
                        }
                        style={{ width: "42px", height: "27px" }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          color: "var(--text_color)",
                          fontSize: "16px",
                        }}
                        pl="10px"
                      >
                        {cc_mask} {cardToShow?.ccNumberMask}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" gap="5px">
                      <Typography
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          color: "var(--primary_color)",
                          fontSize: "16px",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        ml="10px"
                        onClick={manageCardFg}
                      >
                        {change_card}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <ManageCard
                cardData={getCCDetails}
                showCardChangeModal={showCardChangeModal}
                // ChangePlanDetails={ChangePlanDetails}
                setShowCardChangeModal={setShowCardChangeModal}
                isModalRequired={true}
                payType={false}
                isDescription={false}
                pageName={"service"}
                ProceedPayment={PaymentHandler}
                isPayConfirm={true}
                manageCardDetails={
                  manageCardDetails ? manageCardDetails : manageCardContent
                }
                setSelectedCard={setSelectedCard}
                setCardToShow={setCardToShow}
                setShowAutoPayConsentModal={setShowAutoPayConsentModal}
                {...props}
              />
            </Box>
          )}
          {(downgradeNow || downgradeLater) && (
            <Box sx={{ width: "90%", margin: "2rem auto" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "80%",
                  margin: "0 auto",
                  // mx: { xs: "10px", sm: "30px" },
                }}
              >
                {/* <Typography
                  sx={{
                    color: "var(--text_color)",
                    fontFamily: "var(--font_family_Bold)",
                    fontSize: { xs: "1em", md: "16px" },
                  }}
                >
                  {downgrade_txt}
                </Typography> */}
                <Typography
                  sx={{
                    color: "var(--text_color)",
                    fontFamily: "var(--font_family_Semibold)",
                    textAlign: "center",
                    fontSize: { xs: "1em", sm: "16px" },
                  }}
                >
                  {downgrade_note}
                </Typography>
              </Box>
            </Box>
          )}
          {(upgradeNow || upgradeLater || upgradeFlow) && !noCardFound ? (
            <Typography
              sx={{
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Semibold)",
                textAlign: "center",
                fontSize: { xs: "1em", sm: "16px" },
                margin: "10px 0 25px",
                width: "90%",
              }}
            >
              {upgrade_now_txt1} ($
              {planDiffAmount.toFixed(2)}) {upgrade_now_txt2}
            </Typography>
          ) : null}
          {noCardFound && (
            <Typography
              sx={{
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Semibold)",
                textAlign: "center",
                fontSize: { xs: "1em", sm: "16px" },
                margin: "10px 0 25px",
                width: "90%",
              }}
            >
              {no_card_upg_text1}(${planDiffAmount.toFixed(2)})
              {no_card_upg_text2}
            </Typography>
          )}
          {/* Buttons */}
          <>
            <Box sx={{ width: "100%", px: { xs: 0, sm: "30px" } }}>
              {(upgradeNow || upgradeFlow) && (
                <>
                  {noCardFound ? (
                    <ButtonCust
                      sx={{
                        mt: "5px",
                        mb: 0.75,
                        maxWidth: "100% !important",
                        width: "40%",
                        height: "50px !important",
                      }}
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        setShowAutoPayConsentModal(true);
                      }}
                      loading={planChangedLoader}
                    >
                      {add_card}
                    </ButtonCust>
                  ) : (
                    <ButtonCust
                      sx={{
                        mt: "5px",
                        mb: 0.75,
                        maxWidth: "100% !important",
                        width: "40%",
                        height: "50px !important",
                      }}
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        confirmPlanChangeHandler("now", true, cardToShow);
                        gtmTagManager({
                          event: globalVal?.gtmEvents?.changePlanUpgradeNow,
                          click_section: "account",
                        });
                      }}
                      loading={planChangedLoader}
                    >
                      {upgrade_now}
                    </ButtonCust>
                  )}
                </>
              )}
              {/* {upgradeLater && (
                <Typography
                  sx={{
                    color: "var(--primary_color)",
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { xs: "1em", md: "16px" },
                    my: "8px",
                    cursor: "pointer",
                    width: "fit-content",
                    margin: "15px auto",
                  }}
                  onClick={() => {
                    confirmPlanChangeHandler("later", true);
                    gtmTagManager({
                      event: globalVal?.gtmEvents?.changePlanUpgradeLater,
                      click_section: "account",
                    });
                  }}
                >
                  Upgrade Later
                </Typography>
              )} */}

              {downgradeLater && (
                <ButtonCust
                  sx={{
                    mt: "5px",
                    mb: 0.75,
                    maxWidth: "300px",
                    width: "100%",
                    height: "50px !important",
                  }}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    confirmPlanChangeHandler("later", false, cardToShow);
                  }}
                  loading={planChangedLoader}
                >
                  {downgrade_later}
                </ButtonCust>
              )}
            </Box>
          </>
          {/* )}  */}
          {/* <SimpleModal
            isShowModal={showUpgradeModal}
            onCloseModal={() => {
              closeUpgradeModal();
            }}
            showClose={true}
            style={{ borderRadius: "0.3rem" }}
            sx={{
              width: { xs: "90%", sm: "500px", md: "600px" },
              minWidth: { xs: "90%", sm: "500px", md: "600px" },
              maxWidth: "600px",
              borderRadius: "4.8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: "2rem",
              }}
            >
              <Typography
                sx={{
                  color: "var(--light_brown_1)",
                  textAlign: "center",
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: { xs: "1em", md: "16px" },
                  maxWidth: "400px",
                  mb: "1rem",
                }}
              >
                {content?.plan_later_desc_txt}
              </Typography>
              {planChangedLoader ? (
                <Box
                  sx={{
                    textAlign: "center",
                    my: "1rem",
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "var(--checkout_secondary_light_green)",
                    }}
                  />
                </Box>
              ) : (
                <>
                  <ButtonCust
                    sx={{ my: 0.75, maxWidth: "300px", width: "100%" }}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    onClick={() => {
                      confirmPlanChangeHandler("later");
                    }}
                  >
                    {content?.cont_btn_txt}
                  </ButtonCust>
                  <a href="">
                    <Typography
                      sx={{
                        color: "var(--checkout_secondary_light_green)",
                        fontFamily: "var(--font_family_Bold)",
                        fontSize: { xs: "1em", md: "16px" },
                        textDecorationLine: "underline",
                        my: "8px",
                      }}
                      onClick={() => closeUpgradeModal()}
                    >
                      {content?.cancel_txt}
                    </Typography>
                  </a>
                </>
              )}
            </Box>
          </SimpleModal> */}
        </Box>
      </Box>
      {showAutoPayConsentModal && (
        <AutoPayConsentModal
          showAutoPayConsentModal={showAutoPayConsentModal}
          setShowAutoPayConsentModal={setShowAutoPayConsentModal}
          autoPaymentDetails={autoPaymentData}
          autoPayConsent={autoPayConsent}
          setAutoPayConsent={setAutoPayConsent}
          isLoading={isLoading}
          addNewCardHandler={addNewCardHandler}
        />
      )}
    </>
  );
}

export default AccountPlanSummaryTemplate;
