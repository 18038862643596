import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  ButtonCust,
  ButtonCust_T2,
  CustToolTip_T2,
  NotificationCust,
  PlansContainer,
  SimpleModal,
  TextFieldCust,
} from "../../../../components";
import PlanData from "../../../../config/data.json";
import EnvConfig from "../../../../config/env.config.json";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { AccountPlanDetailEnums, CustomerStatus } from "../../../../enums";
import {
  ChangePlan,
  getDeviceStatus,
  rebootDevice,
} from "../../../../features/account/accountSlice";
import {
  activateLine,
  activationStatusCheck,
} from "../../../../features/activation/activationSlice";
import { getCustomerPlan } from "../../../../features/billing/billingSlice";
import {
  GetCustomer,
  getPlanTaxCharges,
  UpdateMacAddress,
  updatePlanDetails,
} from "../../../../features/checkout/checkoutSlice";
import { getPlansByAddress } from "../../../../features/planpage/planpageSlice";
import { ReachPlan } from "../../../../features/trackorder/trackorderSlice";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import {
  modemFormattedVal,
  pushTrackingEventsToGAAndFB,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { globalVal } from "../../../../utils/globalValues/globalvalues";
import { getPlanTaxableAmount } from "../../../checkout/ReviewPlansDetails/BillService";
import { planPriceCalculationHanlder } from "../CPBDP";
import AccountPlanSummaryTemplate from "./AccountSummaryTemplate";
import PlanChangedPage from "./PlanChangedPage";
import styles from "./S2_DataDetails.module.scss";

const DataDetails = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getCustomer, updateMacSuccess, updateMacFailure } = useAppSelector(
    (state: any) => state.checkout
  );
  const { deviceStatus, reboot, rebootLoader } = useAppSelector(
    (state: any) => state.account
  );
  const {
    activateLineStatus,
    activateLineError,
    activationStatus,
    errorActivationStatus,
  } = useAppSelector((state: any) => state?.activation || {});
  const { reachPlan } = useAppSelector((state: any) => state?.trackorder || {});
  const [changeModemModal, setChangeModemModal] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [showActivationFailedModal, setShowActivationFailedModal] =
    useState<any>(false);
  const [loaderModal, setLoaderModal] = useState<any>(false);
  const [confirmedClicked, setConfirmedClicked] = useState(false);
  const [rebootModal, setRebootModal] = useState(false);
  const [iccId, setIccId] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [customerData, setCustomerData] = useState<any>(null);
  const [display, setDisplay] = useState<boolean>(false);
  const { dynamicPlansData, dynamicPlansLoading, errorDynamicPlansData } =
    useAppSelector((state: any) => state.planpage);
  const [planDetails, setPlanDetails] = useState<any>(null);
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [hideAccountPage, setHideAccountPage] = useState<boolean>();
  const [showPlanChangePage, setShowPlanChangePage] = useState<boolean>();
  const [user, setUser] = useState<any>(null);
  const [newPackPlan, setNewPackPlan] = useState<any>(null);
  const [newPlanCost, setNewPlanCost] = useState<number>(0);
  const [currentPlanCost, setCurrentPlanCost] = useState<number>(0);
  const [currentPackPlan, setCurrentPackPlan] = useState<any>(null);
  const [taxesAndFees, setTaxesAndFees] = useState({
    currentPlanTaxes: 0,
    newPlanTaxes: 0,
    diffAmountTaxes: 0,
  });
  const [planChangedLoader, setPlanChangedLoader] = useState(false);
  const [upgradeNow, setUpgradeNow] = useState<any>(null);
  const [upgradeLater, setUpgradeLater] = useState<any>(null);
  const [downgradeNow, setDowngradeNow] = useState<any>(null);
  const [upgradeFlow, setUpgradeFlow] = useState<boolean>(false);
  const [downgradeLater, setDowngradeLater] = useState<any>(null);
  const { isLoadedPlanTaxCharges } = useAppSelector((state) => state.checkout);
  const [autoPayCard, setAutoPayCard] = useState<any>({});
  const [showCardChangeModal, setShowCardChangeModal] = useState<any>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [displayContent, setDisplayContent] = useState("accountPage");
  const [showPlanChanged, setShowPlanChanged] = useState(false);
  const [showScheduledPlanChanged, setShowScheduledPlanChanged] =
    useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [showChangePlanFailed, setShowChangePlanFailed] = useState(false);
  const [loader, setLoader] = useState<boolean>();
  let intervalId: any;
  const {
    title,
    account_id,
    your_plan,
    pricing,
    device_status,
    reboot_text,
    mac_address,
    modem_image,
    bullet_points,
    online_logo,
    modal_title,
    input_label,
    confirm_btn,
    cancel_btn,
    edit_icon,
    label_icon,
    activate_desc,
    activate_title,
    activating,
    wait,
    enter_mac,
    button_text,
    activate,
    please_wait,
    loader_icon,
    please_wait_desc,
    offline_logo,
    reboot_modal_title,
    reboot_modal_desc,
    tooltip_title,
    tooltip_desc,
    download_text,
    upload_text,
    unlimited_text,
    speed_unit,
    list_points,
    activation_fail_title,
    cross,
    activation_fail_desc,
    chat_now,
    inactive_notification,
    activate_notify,
    book_service,
  } = props.content || {};

  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);
  let customerAddress: any = localStorage.getItem("CustomerAddress");
  let currentUserInfo: any = localStorage.getItem("currentuser");
  currentUserInfo = JSON.parse(currentUserInfo);

  const activePlan = customerDetail?.reachPlanId;
  const selectedCurrentPlan = PlanData.data.filter(
    (plan) => plan.name === activePlan
  );

  if (!customerAddress) {
    let shippingAddress = customerDetail?.addresses.filter(
      (value: any) => value.type === "shipping"
    );
    const { address1, address2, city, state, country, zip } =
      shippingAddress[0];
    customerAddress =
      address1 +
      " " +
      address2 +
      " " +
      city +
      " " +
      state +
      " " +
      country +
      " " +
      zip;
    localStorage.setItem("customerAddress", customerAddress);
    var payload: any = {
      address1: address1,
      city: city,
      state: state,
      zip: zip,
      country: "USA",
      type: "billing",
    };
  }

  let address: any = localStorage.getItem("customerAddress");
  const { d_title, cp_action_btn, active_label } = props.content.changeplanData;
  const { getCCDetails, group } = useAppSelector(
    (state: any) => state?.account || {}
  );

  const handleChangePlan = (content: any, user: any = null) => {
    setDisplayContent(content);
    if (user) {
      setCurrentUser(user);
    }
    setDisplay(false);
  };
  const handlePlanChangedConfim = () => {
    setShowPlanChanged(true);
    setShowScheduledPlanChanged(false);
  };
  const handleScheduledPlanChanged = () => {
    setShowScheduledPlanChanged(true);
    setShowPlanChanged(false);
    setShowUpgradeModal(false);
  };
  const handleBack = (props: any) => {
    setDisplayContent(props);
    let planData: any = PlanData.data.find(
      (plan) => plan.name === customerDetail?.reachPlanId
    );
    setPlanDetails(planData);
    setHideAccountPage(false);
    setShowPlanChangePage(true);
    setDisplay(true);
  };

  const manageCardFg = () => {
    setShowCardChangeModal(true);
    gtmTagManager({
      event: globalVal?.gtmEvents?.changePlanChangeCard,
      click_section: "post-activation-home",
    });
  };
  const showCardChangeModalHandle = () => {
    setShowCardChangeModal(false);
  };
  const handleUpgradeModal = () => {
    setShowUpgradeModal(true);
  };
  const closeUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  useEffect(() => {
    PlanData?.data.map((data, index) => {
      data?.name === customerDetail?.reachPlanId
        ? setCurrentPackPlan(data)
        : setNewPackPlan(data);
    });
    let addNewCard: any = localStorage.getItem("addNewCard");
    setUser(customerDetail);
    if (addNewCard) {
      addNewCard = JSON.parse(addNewCard);
      let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
      if (planDetails) {
        planDetails = JSON.parse(planDetails);
        planDetails = planDetails?.plan;
      }
      setSelectedPlan(planDetails);
      setDisplayContent("changePlan");
      updatePlan(customerDetail, planDetails);
      setUpgradeNow(true);
      localStorage.removeItem("addNewCard");
    } else {
      let planData: any = PlanData.data.find(
        (plan) => plan.name === customerDetail?.reachPlanId
      );
      const lines = parseInt(customerDetail.extraLines) + 1;
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({ numberOfLines: lines, plan: planData })
      );
      localStorage.setItem("reachSelectedPlanLine", String(lines));
      dispatch(
        updatePlanDetails({ numberOfLines: lines, selectedPlan: planData })
      );
      let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
      if (planDetails) planDetails = JSON.parse(planDetails);
      setPlanDetails(planData ? planData : planDetails?.plan);
      dispatch(getPlansByAddress(address));
      setHideAccountPage(true);
      setShowPlanChangePage(false);
    }
  }, []);

  useEffect(() => {
    if (getCCDetails) {
      getCCDetails.map((card: any) => {
        if (card.isAutoPay) {
          setAutoPayCard(card);
          return null;
        } else {
          return null;
        }
      });
    }
  }, [getCCDetails]);

  let taxableAmount = getPlanTaxableAmount(planDetails);

  useEffect(() => {
    if (taxableAmount >= 0) {
      dispatch(
        getPlanTaxCharges({
          address: {
            ...payload,
            name: `${customerDetail.firstName} ${customerDetail.lastName}`,
          },
          reachPlanId: planDetails?.name,
          amount: taxableAmount,
        })
      );
    }
  }, [planDetails]);

  useEffect(() => {
    dispatch(
      getDeviceStatus({
        operator: "CABO",
        iccId: customerDetail?.iccId,
      })
    );
    dispatch(ReachPlan({ plan: customerDetail?.reachPlanId }));
    if (customerDetail?.status !== CustomerStatus.INACTIVE)
      dispatch(getCustomerPlan({ customerId: customerDetail?.id }));
  }, []);

  useEffect(() => {
    if (getCustomer?.status === 4) {
      if (getCustomer?.lastDisconnectedReason === 3)
        handleDisconnectNotification("manual");
      else handleDisconnectNotification();
    }

    return () => Store.removeNotification("DisconnectNotificationAccount");
  }, [getCustomer]);

  useNonInitialEffect(() => {
    if (getCustomer) {
      setConfirmedClicked(false);
      setChangeModemModal(false);
      setOpenActivateModal(false);
      setCustomerData(getCustomer);
      setIccId(getCustomer.iccId);
      if (getCustomer.iccId) getDevStatus(getCustomer.iccId);
    }
  }, [getCustomer]);

  useNonInitialEffect(() => {
    if (updateMacSuccess) {
      const formatIccid = iccId?.split(":").join("");
      let email: string =
        customerData && customerData.emailId ? customerData.emailId : "";
      email &&
        dispatch(
          GetCustomer({
            username: encodeURIComponent(email),
          })
        );
      dispatch(
        getDeviceStatus({
          operator: "CABO",
          iccId: formatIccid,
        })
      );
    }
  }, [updateMacSuccess]);

  const getDevStatus = (iddID?: any) => {
    intervalId = setInterval(() => {
      dispatch(
        getDeviceStatus({
          operator: "CABO",
          iccId: iddID,
        })
      );
    }, 30000);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    handleNotifications();
  }, []);

  useNonInitialEffect(() => {
    if (updateMacFailure) {
      setConfirmedClicked(false);
    }
  }, [updateMacFailure]);

  useNonInitialEffect(() => {
    setRebootModal(false);
  }, [reboot]);

  useNonInitialEffect(() => {
    if (activateLineError) {
      setLoaderModal(false);
      setShowActivationFailedModal(true);
    }
  }, [activateLineError]);

  useNonInitialEffect(() => {
    if (activationStatus?.status === 2) {
      setLoaderModal(false);
      setTimeout(() => {
        handleActivationNotification();
      }, 1000);
    } else {
      let customerDetails: any = localStorage.getItem("customerDetail");
      customerDetails = JSON.parse(customerDetails);
      dispatch(
        activationStatusCheck({
          payload: { customerId: customerDetails?.id },
        })
      );
    }
  }, [activationStatus]);

  useNonInitialEffect(() => {
    if (
      activateLineStatus &&
      activateLineStatus?.code === 200 &&
      activateLineStatus?.msg === "Activation In Progress"
    ) {
      let customerDetails: any = localStorage.getItem("customerDetail");
      customerDetails = JSON.parse(customerDetails);
      dispatch(
        activationStatusCheck({
          payload: { customerId: customerDetails?.id },
        })
      );
    }
    // else {
    //   setTimeout(() => {
    //     handleActivationNotification();
    //   }, 1000);
    // }
  }, [activateLineStatus]);

  const handleNotifications = () => {
    let message = "",
      type = "";

    if (getCustomer?.status === CustomerStatus.INACTIVE) {
      message = inactive_notification;
      type = "info";
    }
    Store.removeAllNotifications();
    return message !== ""
      ? NotificationCust({
          message: (
            <Typography
              sx={{
                color: "var(--text_color_11) !important",
                fontFamily: "var(--font_family_Medium) !important",
                fontSize: "16px",
                paddingTop: "50px",
              }}
            >
              {message}
            </Typography>
          ),
          duration: 0,
          id: "activationSuccessNotification",
          type: type,
        })
      : null;
  };
  const rebootHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: gaCategory.reboot,
      buttonValue: confirm_btn,
    });
    const formatIccid = iccId?.split(":").join("");
    dispatch(
      rebootDevice({
        operator: "CABO",
        iccId: formatIccid,
      })
    );
  };

  const toolTipData = () => {
    return (
      <>
        <Typography
          variant="subtitle2"
          color={"var(--primary_brand_color)"}
          fontSize={"17px"}
          fontFamily={"var(--font_family_Semibold)"}
          padding={"10px"}
          borderTop={"4px solid var(--primary_brand_color)"}
          borderBottom={"1px solid rgba(118,118,118,0.7)"}
        >
          {tooltip_title}
        </Typography>
        <Typography
          variant="body2"
          color={"var(--text_color_8)"}
          fontSize={"15px"}
          fontFamily={"var(--font_family_Semibold)"}
          padding={"10px"}
        >
          {tooltip_desc}
        </Typography>
      </>
    );
  };

  const onChangeHandler = (e: any) => {
    // setIccId(e.target.value);
    setIccId(e.target.value.toUpperCase());
  };

  const confirmHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: gaCategory.changeModem,
      buttonValue: confirm_btn,
    });
    const formatIccid = iccId?.split(":").join("");
    dispatch(
      UpdateMacAddress({
        customerId: customerData.id,
        iccId: formatIccid,
      })
    );
    setConfirmedClicked(true);
    setIsEditClicked(false);
  };

  const list = [
    list_points[0]?.desc,
    `${download_text} ${reachPlan?.data?.data?.dlspeed} ${speed_unit}`,
    `${upload_text} ${reachPlan?.data?.data?.ulspeed} ${speed_unit}`,
    list_points[3]?.desc,
  ];

  const handleActivationNotification = () => {
    let message = activate_notify;
    Store.removeAllNotifications();

    return NotificationCust({
      message: (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            paddingTop: "50px",
          }}
        >
          {message}
        </Typography>
      ),
      duration: 0,
      id: "activationSuccessNotification",
      type: "success",
    });
  };
  const handleDisconnectNotification = (disconnectReason?: string) => {
    let custAdd: any = [];
    for (const key in getCustomer?.addresses[1]) {
      if (key !== "name" && key !== "type" && key !== "phone")
        custAdd.push(getCustomer?.addresses[1][key]);
    }
    custAdd = custAdd.join(", ");
    let message =
      disconnectReason === "manual" ? (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            marginTop: "50px",
          }}
        >
          As requested, your service has been disconnected. Please{" "}
          <Link
            to={`${EnvConfig.SUBMIT_TICKET_URL}?firstname=${getCustomer?.firstName}&lastname=${getCustomer?.lastName}&address=${custAdd}&reach_number=${getCustomer?.primaryNumber}&email=${getCustomer?.emailId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "var(--primary_color)" }}
          >
            Raise a Ticket
          </Link>
          &nbsp; if you feel this is a mistake.
        </Typography>
      ) : (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            marginTop: "50px",
          }}
        >
          Your service has been disconnected due to nonpayment of dues. Please
          purchase a plan and &nbsp;
          <Link to={RoutingURLs.billing} state={{ showPayBillModal: true }}>
            Pay Bill
          </Link>
          &nbsp; to resume service.
        </Typography>
      );
    Store.removeAllNotifications();

    return NotificationCust({
      message: message,
      duration: 0,
      id: "DisconnectNotificationAccount",
      type: "danger",
    });
  };

  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: categoryName,
      buttonValue: value,
    });
  };

  const handleActivation = () => {
    GAAndFBEventsHandler(gaCategory.activation, activate);
    setOpenActivateModal(false);
    setShowActivationFailedModal(false);
    setLoaderModal(true);

    let customerDetails: any = localStorage.getItem("customerDetail");
    customerDetails = JSON.parse(customerDetails);
    const formatIccid = iccId.split(":").join("");

    const payload = {
      customerId: customerDetails.id,
      reachPlanId: customerDetails.reachPlanId,
      iccId: formatIccid,
    };
    dispatch(activateLine({ payload: payload }));
  };

  const openDialog = () => {
    let planData: any = PlanData.data.find(
      (plan) => plan.name === customerDetail?.reachPlanId
    );
    setPlanDetails(planData);
    setDisplay(true);
  };
  const closeDialog = () => {
    setDisplay(false);
    setSelectedPlan(undefined);
  };

  const handlePlanSelection = (newPlan: any) => {
    setSelectedPlan(newPlan);
  };

  let billingAddress: any =
    customerDetail &&
    customerDetail.addresses &&
    customerDetail.addresses.find((el: any) => el.type === "billing");
  const { firstName, lastName } = customerDetail || {};
  let code: string = billingAddress && billingAddress.state;

  const calculateUnlimitedCostMnM = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };

  const calculateCost = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;
    const planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    let planPerLinesCost = 0;
    if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    else planPerLinesCost = planCost / SELECTED_LINE;
    return {
      planCost,
      planPerLinesCost,
    };
  };

  const priceCalculationHandler: any = (data: any, selectedLine: any = 1) => {
    let PlanDiscountCost, planPerLinesCost: any;
    if (data?.isUnlimited) {
      const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);

      // PlanDiscountCost = planCost - unlimitedPlanDiscount.data[0].discountInDollar * selectedLine;
    } else {
      const { planCost } = calculateCost(data, selectedLine);
      // PlanDiscountCost = planCost - unlimitedPlanDiscount.data[0].discountInDollar * selectedLine;
    }
    // planPerLinesCost = PlanDiscountCost / selectedLine;
    return planPerLinesCost;
  };

  let ChangePlanDetails: any = {
    planDiffAmount: newPlanCost - currentPlanCost,
    currentPlanCost: currentPlanCost,
    newPlanCost: newPlanCost,
  };

  let differenceAmount = Number(currentPlanCost - newPlanCost);

  const { currentPlanTaxes, newPlanTaxes, diffAmountTaxes } = taxesAndFees;

  const updatePlan = (user?: any, newPlan?: any) => {
    if (newPlan) {
      dispatch(
        updatePlanDetails({
          newPlan: newPlan,
        })
      );

      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          plan: newPlan,
        })
      );
      localStorage.setItem("reachSelectedPlanLine", JSON.stringify(newPlan));
      setPlanDetails(newPlan);
      if (newPlan) {
        setNewPackPlan(newPlan);
      }

      let currentPlan: any = PlanData.data.find(
        (item: any) => item.name === user.reachPlanId
      );
      const additionalActivatedLines = user.totalActiveLines - 1;
      let currentPlanPrice: any = planPriceCalculationHanlder(
        currentPlan,
        additionalActivatedLines,
        user
      );
      let newPlanPrice: any = planPriceCalculationHanlder(
        newPlan,
        additionalActivatedLines,
        user
      );
      setCurrentPlanCost(currentPlanPrice);
      setNewPlanCost(newPlanPrice);
      let differencePlanCost: any = newPlanPrice - currentPlanPrice;
      let payloadcurrentplan = {
        address: {
          name: `${firstName} ${lastName}`,
          address1: billingAddress && billingAddress.address1,
          city: billingAddress && billingAddress.city,
          country: billingAddress && billingAddress.country,
          state: code,
          type: "billing",
          zip: billingAddress && billingAddress.zip,
        },
        reachPlanId: customerDetail.reachPlanId,
        amount: currentPlanPrice,
        // additionalLines: user?.totalActiveLines - 1,
        // discountMap: coupon
        //     ? {
        //           DISCOUNT: coupon?.discountInDollar + (coupon?.seconda * user?.totalActiveLines - 1),
        //       }
        //     : null,
      };
      let payloadnewplan = {
        address: {
          name: `${firstName} ${lastName}`,
          address1: billingAddress && billingAddress.address1,
          city: billingAddress && billingAddress.city,
          country: billingAddress && billingAddress.country,
          state: code,
          type: "billing",
          zip: billingAddress && billingAddress.zip,
        },
        reachPlanId: newPlan
          ? newPlan.name
          : customerDetail
          ? customerDetail.reachPlanId
          : selectedCurrentPlan[0].name,
        amount: newPlanPrice,
        // additionalLines: user?.totalActiveLines - 1,
        // discountMap: coupon
        //     ? {
        //           DISCOUNT:
        //               coupon?.discountInDollar +
        //               (coupon?.secondaryDiscountInDollar * user?.totalActiveLines - 1),
        //       }
        //     : null,
      };
      let planDiffAmount =
        priceCalculationHandler(newPlan) -
        priceCalculationHandler(currentPackPlan);
      setLoader(true);
      dispatch(getPlanTaxCharges(payloadcurrentplan)).then(
        (responsecurrentplan: any) => {
          if (responsecurrentplan?.payload?.status === 200) {
            setTaxesAndFees((prevState) => ({
              ...prevState,
              currentPlanTaxes:
                responsecurrentplan?.payload?.data?.data?.totalTax,
            }));

            dispatch(getPlanTaxCharges(payloadnewplan)).then(
              (responsenewplan: any) => {
                if (responsenewplan?.payload?.status === 200) {
                  setTaxesAndFees((prevState) => ({
                    ...prevState,
                    newPlanTaxes:
                      responsenewplan?.payload?.data?.data?.totalTax,
                  }));
                }
                setDisplayContent("changePlan");
                setLoader(false);
              }
            );
          } else {
            //  setPlanSummaryChangePlan(false);
            //  setplanSummaryChangePlanLoader(false);
          }
        }
      );
    }
    let foundInDowngrade =
      newPlan?.baseLinePrice <= currentPackPlan?.baseLinePrice;
    let foundInUpgrade =
      newPlan?.baseLinePrice > currentPackPlan?.baseLinePrice;

    // Logic to handle Change Plan
    if (foundInUpgrade) {
      if (newPlan?.upgrade === AccountPlanDetailEnums.immediate) {
        setUpgradeNow(true);
        setUpgradeLater(true);
      } else if (
        newPlan?.upgrade === AccountPlanDetailEnums.next_billing_cycle
      ) {
        setUpgradeNow(false);
        setUpgradeLater(true);
      }
    } else if (foundInDowngrade) {
      if (
        newPlan?.downgrade === AccountPlanDetailEnums.immediate ||
        newPlan?.downgrade === AccountPlanDetailEnums.next_billing_cycle
      ) {
        setDowngradeLater(true);
        setDowngradeNow(true);
      }
    }
  };
  const getPlanObject = () => {
    let selectedPlanObj: any = localStorage.getItem("reachSelectedPlanLine");
    let selectedPlanDetail: any = localStorage.getItem(
      "reachSelectedPlanDetail"
    );
    if (selectedPlanDetail) selectedPlanDetail = JSON.parse(selectedPlanDetail);
    if (selectedPlanObj) selectedPlanObj = JSON.parse(selectedPlanObj);
    else selectedPlanObj = selectedPlanDetail?.plan;
    return selectedPlanObj;
  };
  const resetNowAndLaterStates = () => {
    setUpgradeNow(false);
    setUpgradeLater(false);
    setDowngradeNow(false);
    setDowngradeLater(false);
    setSelectedPlan(undefined);
  };

  const confirmPlanChangeHandler = (
    type: string,
    upgrade?: boolean,
    card: any = autoPayCard
  ) => {
    setPlanChangedLoader(true);
    if (type === "later") {
      dispatch(
        ChangePlan({
          // check later
          newReachPlanId: newPackPlan && newPackPlan?.name,
          immediateEffect: false,
          customerId: user && user.id,
          currentReachPlanId: currentPackPlan && currentPackPlan?.name,
          upgraded: upgrade,
          creditCard: {
            uuid: card?.uuid,
          },
        })
      ).then((response: any) => {
        setPlanChangedLoader(false);
        if (response?.payload?.status === 200) {
          dispatch(
            GetCustomer({
              username: encodeURIComponent(
                customerDetail && customerDetail.emailId
              ),
            })
          ).then(() => {
            handleChangePlan("planChangedPage");
            handleScheduledPlanChanged();
            resetNowAndLaterStates();
          });
        } else {
          // NotificationCust({
          //   message: `Some error occured!  Please try again later`,
          //   duration: 2000,
          //   type: "danger",
          //   id: "ErrorChangedLater",
          // });
          setPlanChangedLoader(false);
          setShowChangePlanFailed(true);
          setShowScheduledPlanChanged(false);
          handleChangePlan("planChangedPage");
          resetNowAndLaterStates();
        }
      });
    } else if (type === "now") {
      dispatch(
        ChangePlan({
          newReachPlanId: newPackPlan && newPackPlan?.name,
          immediateEffect: true,
          customerId: user && user.id,
          currentReachPlanId: currentPackPlan && currentPackPlan?.name,
          upgraded: upgrade,
          creditCard: {
            uuid: card?.uuid,
          },
        })
      ).then((response: any) => {
        setPlanChangedLoader(false);
        if (response?.payload?.status === 200) {
          dispatch(
            GetCustomer({
              username: encodeURIComponent(
                customerDetail && customerDetail.emailId
              ),
            })
          ).then(() => {
            handleChangePlan("planChangedPage");
            handlePlanChangedConfim();
            resetNowAndLaterStates();
          });
          let selectedPlanObj = getPlanObject();
          dispatch(
            ReachPlan({
              plan: EnvConfig.BROADBAND
                ? selectedPlanObj?.name
                : customerDetail.reachPlanId,
            })
          );
        } else {
          // NotificationCust({
          //   message: `Some error occured!  Please try again later`,
          //   duration: 2000,
          //   type: "danger",
          //   id: "ErrorChangedNow",
          // });
          setPlanChangedLoader(false);
          setShowChangePlanFailed(true);
          handleChangePlan("planChangedPage");
          resetNowAndLaterStates();
        }
      });
    }
  };

  return (
    <>
      {props.content && displayContent === "accountPage" && (
        <Box className={styles.caboAccContainer}>
          <Grid className={styles.caboGrid}>
            <Box sx={{ display: "flex", gap: { xs: "60px", sm: "120px" } }}>
              <Typography className={styles.heading}>{title}</Typography>
              <Box className={styles.idContainer}>
                {account_id}{" "}
                <Typography component="span">
                  {customerDetail?.accountNumber}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ float: "right" }}>
              <ButtonCust_T2
                variantType="primary_contained_square"
                sx={{
                  width: "200px",
                  height: "40px",
                  fontSize: "20px !important",
                  cursor: "pointer",
                  margin: "auto",
                  color: "var(--white)",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(RoutingURLs.fieldServices);
                  localStorage.setItem("showBookServiceModal", "true");
                }}
              >
                {book_service}
              </ButtonCust_T2>
            </Box>
          </Grid>
          <Grid className={styles.boxContainer}>
            {/* Plan Details */}
            <Box className={styles.detailBox}>
              <Box className={styles.innerBox}>
                <Typography component="div" className={styles.innerContainer}>
                  <Typography component="div" className={styles.boxHeading}>
                    {your_plan}
                  </Typography>
                </Typography>
                <Typography component="div" className={styles.detailsCont}>
                  {
                    <Typography
                      component="div"
                      className={styles.detailsHeadCont}
                    >
                      <Typography
                        component="div"
                        className={styles.detailsHead}
                      >
                        {reachPlan?.data?.data?.name}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "18px",
                          fontFamily: "var(--font_family_Bold)",
                        }}
                      >
                        {reachPlan?.data?.data?.baseLinePrice && (
                          <>
                            ${reachPlan?.data?.data?.baseLinePrice}
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "14px",
                                fontFamily: "var(--font_family_Medium)",
                              }}
                            >
                              {pricing}
                            </Typography>
                          </>
                        )}
                      </Typography>
                    </Typography>
                  }
                  <Typography
                    component="div"
                    className={styles.hLine}
                  ></Typography>
                  <Typography component="div" className={styles.info}>
                    {list.map((el: any, id: any) => (
                      <Typography component="div" key={id}>
                        <Box
                          component="img"
                          src={bullet_points?.data.attributes.url}
                          alt={bullet_points?.data.attributes.alternativeText}
                        />
                        {el}
                      </Typography>
                    ))}
                  </Typography>
                  {getCustomer?.status === CustomerStatus?.ACTIVE && (
                    <Typography component="div" sx={{ display: "grid" }}>
                      <ButtonCust
                        className={styles.changeModemBtn}
                        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                        onClick={openDialog}
                        disabled={
                          getCustomer?.status === CustomerStatus?.DISCONNECTED
                        }
                      >
                        {d_title}
                      </ButtonCust>
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
            {/* Modem Details */}
            <Box className={styles.detailBox}>
              <Box className={styles.innerBox}>
                <Typography component="div" className={styles.innerContainer}>
                  <Typography component="div" className={styles.boxHeading}>
                    {device_status}
                    <Typography
                      component="span"
                      className={styles.onlineStatus}
                    >
                      <Box
                        component="img"
                        src={
                          deviceStatus?.data?.online
                            ? online_logo?.data.attributes.url
                            : offline_logo?.data.attributes.url
                        }
                        alt={
                          deviceStatus?.data?.online
                            ? online_logo?.data.attributes.alternativeText
                            : offline_logo?.data.attributes.alternativeText
                        }
                      />
                      <Typography
                        component="span"
                        style={{
                          color: deviceStatus?.data?.online
                            ? "var(--light_green)"
                            : "var(--shocking-orange)",
                        }}
                      >
                        {deviceStatus?.data?.online ? `Online` : `Offline`}
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography
                    component="div"
                    className={styles.rebootText}
                    onClick={() => setRebootModal(true)}
                  >
                    {reboot_text}
                  </Typography>
                </Typography>
                <Typography component="div" className={styles.detailsCont}>
                  <Typography
                    component="div"
                    className={styles.detailsHeadCont}
                  ></Typography>
                  <Typography component="div" className={styles.modemDetails}>
                    <Box
                      component="img"
                      src={modem_image?.data?.attributes?.url}
                      alt={modem_image?.data.attributes.alternativeText}
                    />
                    {customerData && (
                      <Typography component="div" className={styles.macAdd}>
                        {mac_address}
                        <Typography component="span">{":"}</Typography>
                        <Typography
                          component="span"
                          className={styles.macAddress}
                        >
                          {customerData?.iccId
                            ? customerData?.iccId?.match(/.{1,2}/g).join(":")
                            : ` - `}
                        </Typography>
                      </Typography>
                    )}
                    <ButtonCust
                      className={styles.changeModemBtn}
                      variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        customerData?.iccId
                          ? setChangeModemModal(true)
                          : setOpenActivateModal(true);
                      }}
                      disabled={
                        getCustomer?.status === CustomerStatus?.DISCONNECTED
                      }
                    >
                      {customerData?.iccId ? button_text : activate}
                    </ButtonCust>
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Change Plan Modal */}
          {/* <Grid
                        item
                        container
                        xs={12}
                        className={styles.change_plan_container}
                        sx={{ mb: { md: '9px', sm: '27px', xs: '22px' } }}
                    >
                        <Dialog scroll="body" open={display} onClose={closeDialog} fullWidth maxWidth="sm">
                            <DialogContent>
                                <Close
                                    onClick={closeDialog}
                                    style={{
                                        color: 'var(--balck)',
                                        opacity: '0.5',
                                        fontSize: '20px',
                                        cursor: 'pointer',
                                        float: 'right',
                                    }}
                                />
                                <Box
                                    className="h4"
                                    lineHeight={1.5}
                                    textAlign="center"
                                    fontFamily="var(--font_family_Bold)"
                                    fontWeight="var(--font_weight_1)"
                                    sx={{ fontSize: '24px', marginBottom: '20px' }}
                                >
                                    {d_title}
                                </Box>

                                {dynamicPlansData.length !== 0 ? (
                                    <PlansContainer
                                        content={props.content}
                                        plans={dynamicPlansData}
                                        selectedPlan={selectedPlan}
                                        activePlanName={planDetails?.name}
                                        handlePlanSelection={handlePlanSelection}
                                        currentPlanBorder={true}
                                    />
                                ) : (
                                    <CircularProgress />
                                )}
                                <Stack margin={{ sm: '20px auto', xs: '0 auto' }} width={{ sm: '60%', xs: '80%' }}>
                                    <ButtonCust
                                        style={{ width: '100%' }}
                                        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                                        onClick={updatePlan}
                                    >
                                        {cp_action_btn}
                                    </ButtonCust>
                                </Stack>
                            </DialogContent>
                        </Dialog>
                    </Grid> */}
          <SimpleModal
            isShowModal={display}
            onCloseModal={closeDialog}
            showClose={true}
            sx={{
              width: {
                xs: "90%",
                sm: "100%",
                md: "100%",
                lg: "40%",
                xl: "40%",
              },
              boxShadow:
                "0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11), 0px 4px 8px 0px rgba(79, 94, 113, 0.10), 0px 8px 16px 0px rgba(79, 94, 113, 0.09), 0px 16px 32px 0px rgba(79, 94, 113, 0.08), 0px 32px 64px 0px rgba(79, 94, 113, 0.07)",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "var(--font_family_Bold)",
                fontSize: { xs: "18px", sm: "22px", md: "26px" },
                color: "var(--text_color_8)",
              }}
            >
              {d_title}
            </Typography>
            <Box
              sx={{
                // display: { xs: 'flex', md: 'flex' },
                justifyContent: { xs: "flex-start", md: "space-evenly" },
                overflow: { xs: "scroll", sm: "initial" },
                flexWrap: { xs: "nowrap", sm: "wrap" },
                m: "20px auto",
                p: "4px",
                gap: { xs: "20px", sm: "0px" },
                width: "100%",
              }}
            >
              {dynamicPlansLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : dynamicPlansData.length !== 0 ? (
                <PlansContainer
                  content={props.content}
                  plans={dynamicPlansData}
                  selectedPlan={selectedPlan}
                  activePlanName={planDetails?.name}
                  handlePlanSelection={handlePlanSelection}
                  currentPlanBorder={true}
                  user={customerDetail}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}

              <Stack
                margin={{ sm: "20px auto", xs: "0 auto" }}
                width={{ sm: "60%", xs: "80%" }}
              >
                {/* {!loader ? (
                                    <CircularProgress />
                                ) : ( */}
                <ButtonCust
                  style={{ width: "100%" }}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={() => updatePlan(customerDetail, selectedPlan)}
                  loading={loader}
                  disabled={!selectedPlan}
                >
                  {cp_action_btn}
                </ButtonCust>
                {/* )} */}
              </Stack>
            </Box>
          </SimpleModal>
          {/* Change Modem Modal */}
          <SimpleModal
            isShowModal={changeModemModal}
            onCloseModal={() => {
              setChangeModemModal(false);
              setIsEditClicked(false);
              setIccId(getCustomer.iccId);
            }}
            showClose={true}
            sx={{
              width: {
                xs: "95%",
                sm: "70%",
                md: "80%",
                lg: "45%",
                xl: "30%",
                padding: "30px",
              },
            }}
            style={{ borderRadius: "4px" }}
          >
            <Box className={styles.modalContainer}>
              <Typography className={styles.modalTitle}>
                {modal_title}
              </Typography>
              {confirmedClicked ? (
                <Box className={styles.loadingBox}>
                  <Box
                    component="img"
                    src={loader_icon?.data.attributes.url}
                    alt={loader_icon?.data.attributes.alternativeText}
                  />
                  <Typography sx={{ margin: "15px 0px 26px 0px" }}>
                    {please_wait}
                  </Typography>
                  <Typography>{please_wait_desc}</Typography>
                </Box>
              ) : (
                <>
                  <Box className={styles.textFieldCont}>
                    <Typography className={styles.label}>
                      {input_label}
                      <CustToolTip_T2 title={toolTipData()} arrow>
                        <Box
                          component="img"
                          src={label_icon.data.attributes.url || ""}
                          alt={label_icon?.data.attributes.alternativeText}
                        />
                      </CustToolTip_T2>
                    </Typography>
                    <Typography component="div" className={styles.textField}>
                      <TextFieldCust
                        // value={iccId ? iccId : ""}
                        value={modemFormattedVal(iccId?.toString())}
                        name="macAddress"
                        maxlength={17}
                        // label={iccId ? iccId : "MAC Address"}
                        label={"MAC Address"}
                        onChange={(e: any) => onChangeHandler(e)}
                        className={styles.acc_modal_textfield}
                        sx={{ width: "320px" }}
                        disabled={isEditClicked ? false : true}
                      />
                      {!isEditClicked && (
                        <Box
                          component="img"
                          src={edit_icon?.data.attributes.url}
                          alt={edit_icon?.data.attributes.alternativeText}
                          onClick={() => {
                            setIsEditClicked(true);
                          }}
                        />
                      )}
                    </Typography>
                  </Box>
                  <ButtonCust
                    className={styles.confirmBtn}
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    onClick={confirmHandler}
                    disabled={isEditClicked ? false : true}
                  >
                    {confirm_btn}
                  </ButtonCust>
                  <Typography
                    onClick={() => {
                      setChangeModemModal(false);
                      setIsEditClicked(false);
                    }}
                    className={styles.cancel}
                  >
                    {cancel_btn}
                  </Typography>
                </>
              )}
            </Box>
          </SimpleModal>
          {/* Reboot Modal */}
          <SimpleModal
            isShowModal={rebootModal}
            onCloseModal={() => setRebootModal(false)}
            showClose={true}
            sx={{
              width: {
                xs: "95%",
                sm: "70%",
                md: "80%",
                lg: "45%",
                xl: "30%",
                padding: "30px",
              },
            }}
            style={{ borderRadius: "4px" }}
          >
            <Box className={styles.modalContainer}>
              <Typography className={styles.modalTitle}>
                {reboot_modal_title}
              </Typography>
              <Box className={styles.rebootModalCont}>
                <Typography className={styles.rebootDesc}>
                  {reboot_modal_desc}
                </Typography>
              </Box>
              <ButtonCust
                className={styles.confirmBtn}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={rebootHandler}
                startIcon={rebootLoader ? <CircularProgress /> : reboot_text}
              ></ButtonCust>
              <Typography
                onClick={() => setRebootModal(false)}
                className={styles.cancel}
              >
                {cancel_btn}
              </Typography>
            </Box>
          </SimpleModal>
          {/* Activate Modem Modal */}
          <SimpleModal
            isShowModal={openActivateModal}
            onCloseModal={() => setOpenActivateModal(false)}
            showClose={true}
            sx={{
              width: {
                xs: "90% !important",
                sm: "70% !important",
                md: "45% !important",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <Box
                sx={{
                  color: "var(--text_color_8)",
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "26px",
                }}
              >
                {activate_title}
              </Box>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "14px", sm: "16px" },
                  margin: { xs: "auto", sm: "auto 20px" },
                }}
              >
                {activate_desc}
              </Typography>
              <Box
                sx={{
                  width: { xs: "90%", sm: "80%", md: "60%" },
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    marginBottom: "15px",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {enter_mac}{" "}
                  <CustToolTip_T2 title={toolTipData()} arrow>
                    <Box
                      component="img"
                      src={label_icon.data.attributes.url || ""}
                      alt={label_icon?.data.attributes.alternativeText}
                    />
                  </CustToolTip_T2>
                </Box>
                <TextFieldCust
                  value={modemFormattedVal(iccId?.toString())}
                  name="macAddress"
                  maxlength={17}
                  label={mac_address}
                  onChange={(e: any) => onChangeHandler(e)}
                  className={styles.textField}
                  onBlur={(e) => null}
                />
              </Box>
              <ButtonCust_T2
                variantType={"primary_contained_square"}
                sx={{ width: "180px", margin: "auto" }}
                disabled={!iccId}
                onClick={handleActivation}
              >
                {confirm_btn}
              </ButtonCust_T2>
              <Typography
                sx={{
                  color: "var(--primary_color)",
                  textDecoration: "underline",
                  fontFamily: "var(--font_family_Semibold)",
                }}
                onClick={() => setOpenActivateModal(false)}
              >
                {cancel_btn}
              </Typography>
            </Box>
          </SimpleModal>
          {/* Loader Modal */}
          <SimpleModal
            isShowModal={loaderModal}
            onCloseModal={null}
            showClose={false}
            sx={{
              width: { xs: "50%", sm: "20%", md: "10%" },
              backgroundColor: "var(--white)",
              display: "flex",
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <Box sx={{ fontFamily: "var(--font_family_Bold)" }}>
              {activating}
            </Box>
            <Box
              component="img"
              src={loader_icon?.data.attributes.url}
              alt={loader_icon?.data.attributes.alternativeText}
            />
            <Box className={styles.loader_status}>{wait}</Box>
          </SimpleModal>
          {/* Activation Fail Modal */}
          <SimpleModal
            isShowModal={showActivationFailedModal}
            onCloseModal={() => setShowActivationFailedModal(false)}
            showClose={true}
            className={styles.act_fail_modal_wrapper}
          >
            <Box
              sx={{
                textAlign: "center",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: { sm: "16px", md: "26px" },
                  color: "var(--text_color_8)",
                }}
              >
                {activation_fail_title}
              </Typography>
              <Box
                component="img"
                alt="cross_icon"
                src={cross.data.attributes.url || ""}
                sx={{ width: "40px", margin: "auto" }}
              ></Box>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "16px",
                  color: "var(--text_color_8)",
                }}
              >
                {activation_fail_desc}
              </Box>
              <ButtonCust_T2
                variantType={"primary_contained_square"}
                sx={{ width: "200px", margin: "auto" }}
                onClick={() => setShowActivationFailedModal(false)}
              >
                <a
                  href="#hs-chat-open"
                  rel="noopener noreferrer"
                  style={{ color: "var(--white)", textDecoration: "none" }}
                >
                  {chat_now}
                </a>
              </ButtonCust_T2>
            </Box>
          </SimpleModal>
        </Box>
      )}
      {props.content && displayContent === "changePlan" && (
        <Box className={styles.caboAccContainer}>
          <AccountPlanSummaryTemplate
            handleBack={handleBack}
            currentPackPlan={currentPackPlan}
            taxesAndFees={taxesAndFees}
            newPackPlan={newPackPlan}
            ChangePlanDetails={ChangePlanDetails}
            differenceAmount={differenceAmount}
            autoPayCard={autoPayCard}
            manageCardFg={manageCardFg}
            content={props?.content}
            showCardChangeModal={showCardChangeModal}
            setShowCardChangeModal={setShowCardChangeModal}
            showCardChangeModalHandle={showCardChangeModalHandle}
            confirmPlanChangeHandler={confirmPlanChangeHandler}
            handleUpgradeModal={handleUpgradeModal}
            showUpgradeModal={showUpgradeModal}
            closeUpgradeModal={closeUpgradeModal}
            upgradeNow={upgradeNow}
            setUpgradeNow={setUpgradeNow}
            upgradeLater={upgradeLater}
            setUpgradeLater={setUpgradeLater}
            downgradeNow={downgradeNow}
            setDowngradeNow={setDowngradeNow}
            downgradeLater={downgradeLater}
            setDowngradeLater={setDowngradeLater}
            setPlanChangedLoader={setPlanChangedLoader}
            planChangedLoader={planChangedLoader}
            autoPaymentData={props.autoPaymentData}
            upgradeFlow={upgradeFlow}
          />
        </Box>
      )}
      {props.content && displayContent === "planChangedPage" && (
        <Box className={styles.caboAccContainer}>
          <PlanChangedPage
            showPlanChanged={showPlanChanged}
            showScheduledPlanChanged={showScheduledPlanChanged}
            setShowPlanChanged={setShowPlanChanged}
            setShowScheduledPlanChanged={setShowScheduledPlanChanged}
            handleChangePlan={handleChangePlan}
            content={props?.content}
            showChangePlanFailed={showChangePlanFailed}
            setShowChangePlanFailed={setShowChangePlanFailed}
            setUpgradeFlow={setUpgradeFlow}
          />
        </Box>
      )}
    </>
  );
};

export default DataDetails;
