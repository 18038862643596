import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./SelectCardDialog.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { manageCardsData } from "../../features/billing/billingSlice";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { NotificationCust, ButtonCust, SimpleModal } from "../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPurchaseHistory } from "../../features/billing/billingSlice";
import { selectCardDialog } from "../../features/strapi/billingSlice";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import EnvConfig from "../../config/env.config.json";

type dialogProps = {
  handleClose: any;
  open: boolean;
  dialogData?: any;
  cardHeading?: any;
  icon?: any;
  cardData?: any;
  payConfirmHandler?: any;
  ProceedPayment?: any;
  setShowCardChangeModal?: any;
  pageName?: string;
  backHandler: any;
  selectedCardContent?: any;
  setCardToShow?: any;
};

export const SelectCardDialog = (props: dialogProps) => {
  const [autoBillPay, setAutoBillPay] = useState(false);
  const {
    handleClose,
    open,
    cardHeading,
    icon,
    cardData,
    dialogData,
    payConfirmHandler,
    ProceedPayment,
    setShowCardChangeModal,
    pageName,
    backHandler,
    selectedCardContent,
    setCardToShow,
  } = props;
  const dispatch = useAppDispatch();
  const {
    planChange,
    errorPlanChange,
    planChangeLoader,
    customerTopUpLoader,
    customerTopUp,
    errorCustomerTopUp,
    addLineLoader,
  } = useAppSelector((state: any) => state.account || {});
  const {
    creditCardData,
    updateAutoPayCard,
    errorUpdateAutoPayCard,
    removeCard,
    errorRemoveCard,
    billAutoPay,
    errorBillAutoPay,
  } = useAppSelector((state: any) => state.billing);
  const {
    createInstallationActivityPending,
    createInstallationActivitySuccess,
  } = useAppSelector((state: any) => state.fsm);

  const handleConfirm = () => {
    setAutoBillPay(true);
    if (setCardToShow) {
      setCardToShow(cardData);
      handleClose();
    }
    if (payConfirmHandler) {
      if (cardData.isAutoPay) {
        ProceedPayment && ProceedPayment();
      } else {
        // dispatch(
        //   manageCardsData({
        //     ...creditCardData,
        //     ccuuid: cardData?.uuid,
        //     isLoading: true,
        //     setAsDefault: true,
        //   })
        // );
      }
    } else {
      dispatch(
        manageCardsData({
          ...creditCardData,
          ccuuid: cardData?.uuid,
          isLoading: true,
        })
      );
    }
  };
  const { card_fail_msg, card_dlt_msg, suc_pay_msg, default_crd, cnfrm_btn } =
    selectedCardContent;
  let card_delete_msg = card_dlt_msg?.replace(
    "{ccNumberMask}",
    cardData?.ccNumberMask
  );
  let default_card_msg = default_crd?.replace(
    "{ccNumberMask}",
    cardData?.ccNumberMask
  );
  useNonInitialEffect(() => {
    handleClose();
    if (
      errorUpdateAutoPayCard?.status === "FAILURE" &&
      creditCardData?.setAsDefault
    ) {
      NotificationCust({
        message: errorUpdateAutoPayCard?.message,
        type: "danger",
        duration: 5000,
        id: "UpdateAutoPayCardFailure",
        showIcon: true,
      });
    } else if (updateAutoPayCard && creditCardData?.setAsDefault) {
      if (payConfirmHandler && ProceedPayment) {
        ProceedPayment();
      } else {
        NotificationCust({
          message: default_card_msg,
          type: "info",
          duration: 5000,
          id: "UpdateAutoPayCardSuccess",
          showIcon: true,
        });
      }
    } else if (
      errorRemoveCard?.status === "FAILURE" &&
      creditCardData?.removeCreditCard
    ) {
      NotificationCust({
        message: errorRemoveCard?.message,
        type: "danger",
        duration: 5000,
        id: "RemoveCardFailure",
        showIcon: true,
      });
    } else if (
      removeCard?.data?.status === "SUCCESS" &&
      creditCardData?.removeCreditCard
    ) {
      NotificationCust({
        message: card_delete_msg,
        type: "info",
        duration: 5000,
        id: "RemoveCardSuccess",
        showIcon: true,
      });
    }
    if (billAutoPay?.status === "SUCCESS") {
      setAutoBillPay(false);
      const customerObj = JSON.parse(
        localStorage?.getItem("customerDetail") || "null"
      );
      let startDate = new Date(customerObj?.createdDate).toLocaleDateString(
        "fr-CA"
      );
      let endDate = new Date().toLocaleDateString("fr-CA");
      dispatch(
        getPurchaseHistory({ customerId: customerObj?.id, startDate, endDate })
      );
      NotificationCust({
        message: suc_pay_msg,
        type: "info",
        duration: 5000,
        id: "BillAutoPaySuccess",
        showIcon: true,
      });
    } else if (billAutoPay?.status === "FAILURE") {
      setAutoBillPay(false);
      NotificationCust({
        message: card_fail_msg,
        type: "danger",
        duration: 5000,
        id: "BillAutoPayFailure",
        showIcon: true,
      });
    }

    dispatch(
      manageCardsData({
        ...creditCardData,
        ccuuid: null,
        ...(!payConfirmHandler && { isLoading: false }),
        removeCreditCard: false,
        setAsDefault: false,
      })
    );
    !payConfirmHandler && pageName !== "addlines" && handleClose();
    !payConfirmHandler &&
      pageName === "addlines" &&
      addLineLoader === false &&
      handleClose();
  }, [
    updateAutoPayCard,
    errorUpdateAutoPayCard,
    removeCard,
    errorRemoveCard,
    addLineLoader,
    billAutoPay,
    errorBillAutoPay,
    createInstallationActivityPending,
    createInstallationActivitySuccess,
  ]);

  useNonInitialEffect(() => {
    pageName !== "addlines" && handleClose();
    pageName === "addlines" && addLineLoader === false && handleClose();

    dispatch(
      manageCardsData({
        ...creditCardData,
        isLoading: false,
      })
    );
  }, [
    planChange,
    errorPlanChange,
    customerTopUp,
    errorCustomerTopUp,
    addLineLoader,
  ]);

  return (
    <>
      {open && (
        <SimpleModal
          isShowModal={open}
          onCloseModal={() => {
            handleClose();
            payConfirmHandler && setShowCardChangeModal(true);
          }}
          showClose={pageName === "addlines" ? false : true}
          style={{ borderRadius: "0.3rem" }}
          sx={{
            width: { xs: "95%", sm: "500px", lg: "550px" },
            borderRadius: "4.8px",
          }}
          icon={icon ? icon : null}
        >
          {pageName === "addlines" && (
            <ArrowBackIcon
              className={styles.arrow_icon}
              onClick={() => backHandler()}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            paddingBottom="15px"
          >
            {cardHeading && (
              <Typography className={styles.heading}>{cardHeading}</Typography>
            )}
            <img
              src={
                cardData?.type?.toLowerCase() === "visa"
                  ? dialogData?.visa?.data?.attributes?.url
                  : cardData?.type?.toLowerCase() === "mastercard"
                  ? dialogData?.master_card?.data?.attributes?.url
                  : cardData?.type?.toLowerCase() === "jcb"
                  ? dialogData?.jcb?.data?.attributes?.url
                  : dialogData?.american_express?.data?.attributes?.url
              }
              className={styles.visa_img}
            />
            <Typography className={styles.card_name}>
              {cardData.type}
            </Typography>
            <Typography className={styles.card_number}>
              XXXX-XXXX-XXXX- {cardData.ccNumberMask}
            </Typography>
            <Typography className={styles.expiry}>
              {cardData.nameOnCard} | Expiration date{" "}
              {cardData.dateOfExpiry.slice(0, 2)}/
              {cardData.dateOfExpiry.slice(2)}
            </Typography>
            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              className={styles.confirm_button}
              onClick={handleConfirm}
              loading={
                creditCardData?.isLoading ||
                planChangeLoader ||
                customerTopUpLoader ||
                addLineLoader ||
                autoBillPay
              }
            >
              {cnfrm_btn}
            </ButtonCust>
          </Box>
        </SimpleModal>
      )}
    </>
  );
};
