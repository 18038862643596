import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import Config from "../../../../config/env.config.json";
import { customerGroup } from "../../../../features/account/accountSlice";
import { ButtonCust } from "../../../widgets";

const PlanChangedPage = (props: any) => {
  const {
    showPlanChanged,
    showScheduledPlanChanged,
    handleChangePlan,
    setShowPlanChanged,
    setShowScheduledPlanChanged,
    content,
    showChangePlanFailed,
    setShowChangePlanFailed,
    setUpgradeFlow,
  } = props;

  const {
    plan_changed,
    plan_changed_note,
    continue_txt,
    plan_changed_scd,
    plan_changed_scd_note,
    plan_changed_req_fail,
    try_again_txt,
  } = props?.content?.changeplanData;
  const dispatch = useAppDispatch();

  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  return (
    <Stack>
      {showPlanChanged && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            my: "3rem",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "50px",
            }}
            src={content?.success_gif?.data?.attributes?.url}
            alt={content?.success_gif?.data?.attributes?.alternativeText}
          />

          <Typography
            sx={{
              textAlign: "center",
              color: "var(--black)",
              fontFamily: "var(--font_family_Bold)",
              fontSize: { xs: "24px" },
            }}
            my="10px"
          >
            {plan_changed}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--black)",
              fontSize: { sm: "16px" },
              textAlign: "center",
              maxWidth: { xs: "320px", sm: "450px", md: "550px", lg: "37%" },
            }}
          >
            {plan_changed_note}
          </Typography>

          <ButtonCust
            sx={{ my: "2rem", maxWidth: "300px", width: "100%" }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              setShowPlanChanged(false);
              setShowScheduledPlanChanged(false);
              dispatch(
                customerGroup({
                  groupId: customerDetails.groupId,
                })
              );
              handleChangePlan("accountPage", customerDetails);
            }}
          >
            {continue_txt}
          </ButtonCust>
        </Box>
      )}

      {showScheduledPlanChanged && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            my: "3rem",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "60px",
              height: "59px",
            }}
            src={content?.in_progress_gif?.data?.attributes?.url}
            alt={content?.in_progress_gif?.data?.attributes?.alternativeText}
          />

          <Typography
            sx={{
              textAlign: "center",
              color: "var(--title-color_1)",
              fontFamily: "var(--font_family_Bold)",
              fontSize: { xs: "1.5em", md: "24px" },
              fontWeight: "var(--font_weight_4)",
              lineHeight: "30px",
            }}
            my="10px"
          >
            {plan_changed_scd}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
              fontWeight: "var(--font_weight_0)",
              fontSize: { sm: "0.875em", md: "16px" },
              textAlign: "center",
              lineHeight: "28px",
              maxWidth: { xs: "300px", sm: "400px", md: "500px", lg: "40%" },
            }}
          >
            {plan_changed_scd_note}
          </Typography>

          <ButtonCust
            sx={{
              my: "2rem",
              maxWidth: "300px",
              width: "100%",
              fontSize: "16px",
              fontWeight: "var(--font_weight_2)",
              lineHeight: "26px",
              fontFamily: "var(--font_family_Semibold)",
            }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              handleChangePlan("accountPage");
              setShowPlanChanged(false);
              setShowScheduledPlanChanged(false);
              dispatch(
                customerGroup({
                  groupId: customerDetails.groupId,
                })
              );
            }}
          >
            {continue_txt}
          </ButtonCust>
        </Box>
      )}
      {showChangePlanFailed && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            my: "3rem",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "60px",
              height: "59px",
            }}
            src={content?.red_cross?.data?.attributes?.url}
            alt={content?.cross_img?.data?.attributes?.alternativeText}
          />

          <Typography
            sx={{
              textAlign: "center",
              color: "var(--text_color)",
              fontFamily: "var(--font_family_Bold)",
              fontSize: { xs: "1.5em", md: "40px" },
            }}
            my="10px"
          >
            {plan_changed_req_fail}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
              fontSize: { sm: "0.875em", md: "18px" },
              textAlign: "center",
              maxWidth: { xs: "300px", sm: "400px", md: "500px", lg: "33%" },
            }}
          >
            {content?.plan_faild_desc}
          </Typography>

          <ButtonCust
            sx={{ my: "2rem", maxWidth: "300px", width: "100%" }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              handleChangePlan("changePlan");
              setShowPlanChanged(false);
              setShowScheduledPlanChanged(false);
              setShowChangePlanFailed(false);
              setUpgradeFlow(true);
              dispatch(
                customerGroup({
                  groupId: customerDetails.groupId,
                })
              );
            }}
          >
            {try_again_txt}
          </ButtonCust>
        </Box>
      )}
    </Stack>
  );
};

export default PlanChangedPage;
