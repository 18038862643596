import Config from "../../../config/env.config.json";
declare global {
  interface Window {
    invite_referrals: any;
  }
}
/**
 * When using this reusable widget,
 * 1. call inviteFriends in useEffect.
 * 2. call inviteFriendsHandler in the onClick.
 */
export const inviteFriends = () => {
  let currentUser: any = localStorage.getItem("currentuser");
  currentUser = JSON.parse(currentUser);

  var invite_referrals = window.invite_referrals || {};

  (function () {
    invite_referrals.auth = {
      bid_e: Config.INVITE_REFERRALS_BID_E,
      bid: Config.INVITE_REFERRALS_BID,
      t: Config.INVITE_REFERRALS_T,
      userParams: { fname: currentUser?.displayName?.split(" ")[0] || null },
      email: currentUser?.email || null,
    };
    invite_referrals.async = false;
    window.invite_referrals = invite_referrals;
    var script = document.createElement("script");
    script.src = Config.REFERRALS_SCRIPT_SRC || "";
    var entry: any = document.getElementsByTagName("script")[0];
    entry.parentNode.insertBefore(script, entry);
  })();
};

export const inviteFriendsHandler = () => {
  let params: any = {
    bid: Config.INVITE_REFERRALS_BID,
    cid: Config.INVITE_REFERRALS_CAMPAIGNID,
  };
  window?.invite_referrals?.widget?.inlineBtn(params);
};
