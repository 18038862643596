export const CardTypes = {
    VISA: 'VISA',
    MASTERCARD: 'MASTERCARD',
    JCB: 'JCB',
    AMERICANEXPRESS: 'AMERICAN EXPRESS',
    DISCOVER: 'DISCOVER',
    AMEX: 'AMEX',
};
export enum ShipmentTypes {
    MODEM = 'MODEM',
    BYOD = 'BYOD',
}
export enum OrderStatuses {
    DELIVERED = 'DELIVERED',
    ORDERED = 'ORDERED',
}

export const AccountPlanDetailEnums = {
    immediate: 'IMMEDIATE',
    next_billing_cycle: 'NEXT_BILL_CYCLE',
};
