import { Box, Typography } from "@mui/material";
import { getIsCardExpired } from "../../../utils/commonFunctions/ReusableFunctions";
import { ButtonCust_T2 } from "../Button_T2";
import styles from "./ManageCards.module.scss";

type ManageProps = {
  manageCardDetails?: any;
  isDescription?: any;
  getCustomer?: any;
  cardData?: any;
  payType?: any;
  selectOpenHandler?: any;
  openHandler?: any;
  addNewCardHandler?: any;
  getCardsInOrder?: any;
  isLoading?: boolean;
  setShowAutoPayConsentModal?: any;
};

const CardChangeModal = (props: ManageProps) => {
  const {
    manageCardDetails,
    isDescription,
    cardData,
    payType,
    selectOpenHandler,
    addNewCardHandler,
    getCardsInOrder,
    openHandler,
    getCustomer,
    isLoading,
    setShowAutoPayConsentModal,
  } = props;

  return (
    <Box className={styles.manage_card_container}>
      <Typography className={styles.card_heading}>
        {manageCardDetails?.tile_heading}{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: "18px",
          fontFamily: "var(--font_family_Semibold)",
          marginTop: "15px",
        }}
      >
        {manageCardDetails?.tile_description}
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          fontFamily: "var(--font_family_Medium)",
          margin: "15px 0",
          color: "var(--text_color_8)",
          width: "100%",
          textAlign: "left",
        }}
      >
        {manageCardDetails?.saved_cards_title}
      </Typography>

      {isDescription ? (
        <Typography className={styles.description}>
          {manageCardDetails?.description?.replace(
            "{0}",
            `${new Date(getCustomer?.nextBillingDate).getDate()}`
          )}
        </Typography>
      ) : null}
      <Box className={styles.bottom_section}>
        <Typography className={styles.save_card_text}>
          {manageCardDetails?.save_cards}
        </Typography>
        <>
          {cardData?.length > 0 &&
            getCardsInOrder(cardData).map((each: any, ind: number) => {
              let cardIsExpired = getIsCardExpired(each);
              return (
                <Box
                  sx={{
                    width: "100%",
                    border: each?.isDefault
                      ? "1px solid var(--primary_color)"
                      : "1px solid var(--white_3)",
                    padding: "15px",
                    boxSizing: "border-box",
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                  key={ind}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                      }}
                    >
                      XXXX-XXXX-XXXX- {each.ccNumberMask}
                    </Typography>
                    <Box
                      component="img"
                      alt="visa_image"
                      sx={{
                        width: "30px",
                        height: "20px",
                        marginRight: "10px",
                        alignSelf: "flex-start",
                      }}
                      src={
                        each?.type?.toLowerCase() === "visa"
                          ? manageCardDetails?.visa?.data?.attributes?.url
                          : each?.type?.toLowerCase() === "mastercard"
                          ? manageCardDetails?.master_card?.data?.attributes
                              ?.url
                          : each?.type?.toLowerCase() === "jcb"
                          ? manageCardDetails?.jcb?.data?.attributes?.url
                          : manageCardDetails?.american_express?.data
                              ?.attributes?.url
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                      }}
                    >
                      {each && each.nameOnCard}
                    </Typography>
                    <Typography
                      className={styles.expiry}
                      sx={{
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                      }}
                    >
                      {cardIsExpired ? (
                        <Box
                          component={"span"}
                          sx={{
                            color: "red",
                            fontSize: "14px",
                            fontFamily: "var(--font_family_Semibold)",
                          }}
                        >
                          {manageCardDetails?.expired_on_text}{" "}
                          {each &&
                            each.dateOfExpiry &&
                            each.dateOfExpiry.slice(0, 2)}
                          /
                          {each &&
                            each.dateOfExpiry &&
                            each.dateOfExpiry.slice(2)}
                        </Box>
                      ) : (
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: "14px",
                            fontFamily: "var(--font_family_Semibold)",
                          }}
                        >
                          {manageCardDetails?.expires_on_text}{" "}
                          {each &&
                            each.dateOfExpiry &&
                            each.dateOfExpiry.slice(0, 2)}
                          /
                          {each &&
                            each.dateOfExpiry &&
                            each.dateOfExpiry.slice(2)}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {payType ? (
                      <Box display="flex" alignItems="center">
                        {!each.isAutoPay && (
                          <Typography
                            onClick={() => {
                              if (!cardIsExpired)
                                selectOpenHandler(
                                  manageCardDetails?.set_default,
                                  each,
                                  true
                                );
                            }}
                            className={styles.update_text}
                            sx={{
                              opacity: cardIsExpired ? 0.5 : 1,
                              cursor: `${
                                cardIsExpired
                                  ? "not-allowed !important"
                                  : "pointer"
                              }`,
                            }}
                          >
                            {manageCardDetails?.set_default}
                          </Typography>
                        )}
                        <Typography
                          onClick={() => openHandler(each)}
                          className={styles.update_text}
                        >
                          {manageCardDetails?.update}
                        </Typography>
                        {!each.isAutoPay && (
                          <Typography
                            onClick={() =>
                              selectOpenHandler(
                                manageCardDetails?.remove_card,
                                each,
                                false
                              )
                            }
                            className={styles.update_text}
                          >
                            {manageCardDetails?.remove}
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      !cardIsExpired && (
                        <Typography
                          sx={{
                            fontFamily: "var(--font_family_Semibold)",
                            fontSize: "16px",
                            textDecoration: "underline",
                            color: "var(--primary_color)",
                            cursor: "pointer",
                          }}
                          onClick={() => selectOpenHandler("", each, true)}
                        >
                          {manageCardDetails?.pay_using}
                        </Typography>
                      )
                    )}
                    {each?.isDefault && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "var(--font_family_Semibold)",
                          color: "var(--text_color_8)",
                        }}
                      >
                        {manageCardDetails?.default_text}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
        </>
      </Box>
      <ButtonCust_T2
        sx={{ padding: "13px 23px 12px" }}
        onClick={() => setShowAutoPayConsentModal(true)}
        // onClick={addNewCardHandler}
        loading={isLoading}
      >
        <Typography
          fontSize="25px"
          mr="5px"
          fontFamily="var(--font_family_Bold)"
        >
          {manageCardDetails?.plus}
        </Typography>
        <Typography
          sx={{
            color: "var(--white)",
            fontFamily: "var(--font_family_Bold)",
            fontSize: "20px",
          }}
        >
          {manageCardDetails?.add_card_btn}
        </Typography>
      </ButtonCust_T2>
    </Box>
  );
};

export default CardChangeModal;
