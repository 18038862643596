import { Box, Typography } from "@mui/material";
import styles from "./S1_FAQ.module.scss";
import { FAQTemplateProps } from "./FAQ";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const FAQ_template = (props: FAQTemplateProps) => {
  const { FAQ_title, QandA } = props.content || {};
  const [showAnswer, setShowAnswer] = useState<any>(false);
  const handleShowAns = (i: any) => {
    setShowAnswer(i);
  };
  const handleHideAns = (i: any) => {
    setShowAnswer(null);
  };

  return (
    <Box
      sx={{
        padding: { xs: "0 15px", sm: "0 30px 30px", md: "0 100px 100px" },
        backgroundColor: "var(--white)",
      }}
    >
      <Typography
        sx={{
          color: "var(--text_color_9)",
          fontSize: { md: "38px" },
          fontFamily: "var(--font_family_Bold)",
          textAlign: "center",
        }}
      >
        {FAQ_title}
      </Typography>
      <Box sx={{ marginTop: "50px" }}>
        <hr></hr>
        {QandA?.map((item: any, i: any) => {
          return (
            <Box key={item.Q}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    color: "var(--primary_color)",
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { md: "22px" },
                  }}
                >
                  {item.Q}
                </Typography>
                {i !== showAnswer && (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "var(--primary_color)",
                      width: "45px",
                      height: "45px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleShowAns(i);
                    }}
                  />
                )}
                {i === showAnswer && (
                  <KeyboardArrowUpIcon
                    sx={{
                      color: "var(--primary_color)",
                      width: "45px",
                      height: "45px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleHideAns(i);
                    }}
                  />
                )}
              </Box>
              {i === showAnswer && (
                <Typography
                  sx={{
                    color: "var(--text_color_11)",
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { md: "20px" },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.A,
                  }}
                ></Typography>
              )}
              <hr></hr>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FAQ_template;
