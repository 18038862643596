import { Box, Card, CardHeader, Typography } from "@mui/material";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { ButtonCust_T2 } from "../../widgets";
import { paymentLandingProps } from "./PaymentLandingComponent";
import { Link, useNavigate } from "react-router-dom";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import EnvConfig from "../../../config/env.config.json";
import { OrderStatuses, ShipmentTypes } from "../../../enums";
import _ from "lodash";
import ScheduleInstallationComponent from "../ScheduleInstallation/ScheduleInstallationComponent";

const PaymentLanding = (props: paymentLandingProps) => {
  const {
    content,
    shipmentType,
    orderStatus,
    pickUpInfo,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    selectedDateCal,
    setSelectedDateCal,
    callInstallationActivity,
    loader,
  } = props;

  const navigate = useNavigate();
  const customerDetails = JSON.parse(
    localStorage.getItem("customerDetail") || ""
  );

  const shippingAddress = customerDetails?.addresses.filter(
    (address: any) => address.type === "shipping"
  );

  let activityInfo: any = localStorage.getItem("activityInfo");
  if (activityInfo) {
    activityInfo = JSON.parse(activityInfo);
  }
  const {
    pickup_location,
    pickup_location_icon,
    note,
    note_desc,
    pick_success_icon,
    pick_success_message,
    not_picked_up_1,
    not_picked_up_2,
    not_picked_up_3,
    byod_desc,
  } = content;

  let customerAddress: any = [];
  for (const key in shippingAddress[0]) {
    if (key !== "name" && key !== "type" && key !== "phone")
      customerAddress.push(shippingAddress[0][key]);
  }
  customerAddress = customerAddress.join(", ");

  const RenderCards = () => {
    const isModemDelivered = orderStatus === OrderStatuses.DELIVERED;

    const text_style_1 = {
      color: "inherit",
      fontSize: "inherit",
      fontFamily: "inherit",
      lineHeight: "inherit",
    };
    const Card1 = (
      <Card sx={{ mb: 2, mt: 1 }} elevation={0} variant="elevation" square>
        <CardHeader
          title={
            isModemDelivered ? (
              <>
                <Typography
                  component={"span"}
                  sx={{
                    color: "var(--text_color)",
                    fontSize: { xs: "14px", sm: "16px" },
                    fontFamily: "var(--font_family_Semibold)",
                    lineHeight: "24px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={text_style_1}>
                    {not_picked_up_1}&nbsp;
                  </Typography>
                  <Typography>
                    <Link
                      to={`${EnvConfig.SUBMIT_TICKET_URL}?firstname=${customerDetails?.firstName}&lastname=${customerDetails?.lastName}&address=${customerAddress}&reach_number=${customerDetails?.primaryNumber}&email=${customerDetails?.emailId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        ...text_style_1,
                        color: "var(--primary_brand_color)",
                        textDecorationThickness: "1px",
                      }}
                    >
                      {not_picked_up_2}
                    </Link>
                    &nbsp;
                  </Typography>
                  <Typography sx={text_style_1}>{not_picked_up_3}</Typography>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  component="div"
                  sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <Typography
                    sx={{
                      color: "var(--primary_brand_color)",
                      fontSize: { xs: "16px", md: "18px" },
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {pickup_location}
                  </Typography>
                  <Box
                    component="img"
                    alt={
                      pickup_location_icon?.data?.attributes?.alternativeText
                    }
                    height="12px"
                    width="auto"
                    src={pickup_location_icon?.data?.attributes?.url}
                  />
                </Typography>
              </>
            )
          }
          titleTypographyProps={{
            variant: "h5",
            fontSize: "20px",
            fontFamily: "var(--font_family_Semibold)",
            color: "var(--text_color_8)",
          }}
          sx={{
            p: "15px",
          }}
        />
      </Card>
    );
    const Card2 = (
      <Card
        sx={{
          mb: 2,
          mt: 1,
          boxShadow: "3px 3px 6px 0 rgba(0,0,0,0.09)",
          border: "unset",
        }}
        square
      >
        <CardHeader
          title={
            isModemDelivered ? (
              <>
                <Box
                  component="img"
                  height="30px"
                  width="auto"
                  alt={pick_success_icon?.data?.attributes?.alternativeText}
                  src={pick_success_icon?.data?.attributes?.url}
                />
                <Typography
                  sx={{
                    color: "var(--text_color)",
                    fontSize: { xs: "14px", sm: "16px" },
                    fontFamily: "var(--font_family_Semibold)",
                    lineHeight: "24px",
                    px: "10px",
                  }}
                >
                  {pick_success_message}
                </Typography>
              </>
            ) : (
              <Typography component="div">
                <Typography
                  sx={{
                    color: "var(--text_color)",
                    fontSize: { xs: "16px", sm: "20px", md: "26px" },
                    fontFamily: "var(--font_family_Medium)",
                    lineHeight: { xs: "24px", md: "30px" },
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textAlign: "left",
                  }}
                >
                  {_.values(pickUpInfo?.pickupFrom).join(", ")}
                </Typography>
                <Typography component={"div"} sx={{ mt: "15px" }}>
                  {pickUpInfo?.pickupWindow?.map((data: any) => (
                    <Typography
                      sx={{
                        color: "var(--text_color)",
                        fontSize: { xs: "14px", md: "18px" },
                        fontFamily: "var(--font_family_Semibold)",
                        lineHeight: { xs: "14px", sm: "19.2px", md: "24px" },
                        textAlign: "left",
                      }}
                      key={data.pickupDays}
                    >
                      {_.values(data).join(", ")}
                    </Typography>
                  ))}
                </Typography>
              </Typography>
            )
          }
          titleTypographyProps={{
            variant: "h5",
            fontSize: "20px",
            fontFamily: "var(--font_family_Semibold)",
            color: "var(--text_color_8)",
          }}
          sx={{
            py: "30px",
          }}
        />
      </Card>
    );
    const ActivationTip = (
      <Typography
        component={"div"}
        sx={{
          display: "flex",
          fontFamily: "var(--font_family_Semibold)",
          fontSize: "18px",
        }}
      >
        <Typography
          component={"span"}
          sx={{
            color: "var(--primary_brand_color)",
            fontFamily: "inherit",
            fontSize: "inherit",
          }}
        >
          {note}&nbsp;
        </Typography>
        <Typography
          component={"span"}
          sx={{
            color: "var(--text_color)",
            fontFamily: "inherit",
            fontSize: "inherit",
          }}
        >
          {note_desc}
        </Typography>
      </Typography>
    );
    return !customerDetails?.meta?.hasFsmActivityPending ? (
      isModemDelivered ? (
        <>
          {Card2}
          {Card1}
        </>
      ) : (
        <>
          {Card1}
          {Card2}
          {ActivationTip}
        </>
      )
    ) : null;
  };

  return (
    <>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "70%", lg: "65%" },
          margin: "100px auto 0",
          textAlign: "center",
        }}
      >
        <Box
          component={"img"}
          alt={content?.tick_icon?.data?.attributes?.alternativeText}
          src={content?.tick_icon?.data?.attributes?.url}
          sx={{ marginTop: "50px" }}
        />
        <Typography
          sx={{
            fontSize: "38px",
            fontFamily: "var(--font_family_Medium)",
            margin: "24px auto 30px",
          }}
        >
          {content?.heading}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "18px",
            fontFamily: "var(--font_family_Semibold)",
            margin: "0 6%",
          }}
        >
          {content?.payment_desc1}&nbsp;{customerDetails?.emailId}
          {content?.payment_desc2}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontFamily: "var(--font_family_Semibold)",
            color: "var(--text_color)",
            margin: "35px auto 55px",
            lineHeight: "16px",
            "& span": {
              fontWeight: "700",
            },
          }}
          dangerouslySetInnerHTML={{
            __html: `${content?.acc_id}<span>${customerDetails?.accountNumber}</span>`,
          }}
        />
        <Box
          sx={{
            width: { xs: "80%", md: "80%" },
            display: "flex",
            justifyContent: "center",
            mx: "auto",
            pb: "62px",
          }}
        >
          <Box
            component={"img"}
            alt={content?.plan_icon?.data?.attributes?.alternativeText}
            src={content?.plan_icon?.data?.attributes?.url}
          />
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Medium)",
              height: "40px",
              width: { xs: "100px", md: "121px" },
              border: "2px solid var(--white)",
              backgroundColor: "var(--lightgrey_6)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "var(--text_color)",
            }}
          >
            {content?.plan_detail}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Medium)",
              height: "40px",
              width: { xs: "150px", md: "200px" },
              backgroundColor: "#e6d9e9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "var(--primary_color)",
            }}
          >
            {customerDetails?.reachPlanId}
          </Typography>
        </Box>
        {customerDetails?.meta?.hasFsmActivityPending &&
        activityInfo?.data?.status === "pending" ? null : customerDetails?.meta
            ?.hasFsmActivityPending ? null : (
          <>
            {shipmentType === ShipmentTypes.MODEM ? <RenderCards /> : null}
            <ButtonCust_T2
              variantType="primary_contained_square"
              sx={{
                marginBottom:
                  shipmentType === ShipmentTypes.MODEM ? "200px" : "0px",
                mt: shipmentType === ShipmentTypes.MODEM ? "40px" : "8px",
                pt: "13px",
                width: "300px",
                fontSize: "20px !important",
              }}
              onClick={() => {
                pushTrackingEventsToGAAndFB(
                  gaEventTypes.event,
                  gaEventTypes.click,
                  {
                    appName: EnvConfig.brand,
                    screenName: gaScreenNames.paymentLanding,
                    category: gaCategory.setupModem,
                    buttonValue: content?.modem_setup_btn,
                  }
                );
                navigate(RoutingURLs.account);
              }}
            >
              {content?.modem_setup_btn}
            </ButtonCust_T2>
            {shipmentType === ShipmentTypes.BYOD ? (
              <Typography
                sx={{
                  p: "40px 0px 100px",
                  fontSize: "18px",
                  lineHeight: "28px",
                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {byod_desc}
              </Typography>
            ) : null}
          </>
        )}
      </Box>
      {customerDetails?.meta?.hasFsmActivityPending &&
      activityInfo?.data?.status !== "pending" ? (
        <ScheduleInstallationComponent
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          selectedDateCal={selectedDateCal}
          setSelectedDateCal={setSelectedDateCal}
          callInstallationActivity={callInstallationActivity}
          loader={loader}
        />
      ) : null}
    </>
  );
};

export default PaymentLanding;
