import { Box } from "@mui/material";
import classes from "./T2_PlanItem.module.scss";
import cn from "classnames";

import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import Config from "../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../Types/GoogleAnalytics";

interface T2_PlanItemProps {
  selectedPlan?: any;
  plan?: any;
  activePlanName?: any;
  key?: string | number;
  handlePlanSelection: any;
  content: any;
  currentPlanBorder: boolean;
  user: any;
}
export const T2_PlanItem = ({
  selectedPlan,
  plan,
  activePlanName,
  key,
  handlePlanSelection,
  content,
  currentPlanBorder,
  user,
}: T2_PlanItemProps) => {
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };

  return (
    <Box>
      {!user?.reachPlanChangeDate && user?.amendedReachPlanId === plan.name ? (
        <Box className={classes.move_next_month_label}>
          {content?.move_next_month}
        </Box>
      ) : activePlanName === plan.name ? (
        <Box className={classes.active_item_label}>
          {content?.changeplanData.active_label}
        </Box>
      ) : null}
      <Box
        key={key}
        onClick={
          activePlanName !== plan.name
            ? () => {
                handlePlanSelection(plan);
                GAAndFBEventsHandler(gaCategory.changePlan, plan.name);
              }
            : () => null
        }
        className={
          activePlanName === plan.name
            ? classes.active_plan
            : classes.in_active_plan
        }
        id={currentPlanBorder ? "actvie_card_border" : "none"}
      >
        <Box
          className={cn(
            activePlanName === plan.name
              ? classes.CheckoutCardActive
              : classes.CheckoutCard,
            JSON.stringify(selectedPlan) === JSON.stringify(plan) &&
              classes.ActiveCard
          )}
        >
          <Box className={classes.leftDiv}>
            <Box className={classes.cardTitle}>
              {plan && plan.name && plan.name.replace("The", "")}
            </Box>
          </Box>
          <Box className={classes.rightDiv}>
            <Box className={classes.gbData}>
              {`$${plan.baseLinePrice.toFixed(2)}`}
              <span
                className={
                  JSON.stringify(selectedPlan) === JSON.stringify(plan)
                    ? classes.monthDataActive
                    : classes.monthData
                }
              >
                {content?.changeplanData?.line_unit}
              </span>
            </Box>
            <Box className={classes.sharable}>
              {plan && plan.isUnlimited
                ? content?.changeplanData?.unlimited
                : ""}
            </Box>

            <Box className={classes.addLine}>
              {content?.changeplanData?.download_msg.replace(
                "DLSpeed",
                plan?.dlspeed
              )}
            </Box>
            <Box className={classes.addLine}>
              {content?.changeplanData?.upload_msg.replace(
                "UPSpeed",
                plan?.ulspeed
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default T2_PlanItem;
