import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import EnvConfig from "../../../config/env.config.json";
import {
  Coupon,
  getActivityTypes,
  getDeviceTaxCharges,
  getInstallTaxCharges,
  getPlanTaxCharges,
  resetCoupon,
  updateFormValues,
  updatePlanDetails,
} from "../../../features/checkout/checkoutSlice";
import { getPlansByAddress } from "../../../features/planpage/planpageSlice";
import { useNonInitialEffect } from "../../../utils/commonFunctions";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { PlansContainer } from "../../PlanSelection";
import { ButtonCust, CustToolTip_T2 } from "../../widgets";
import { NotificationCust } from "../../widgets/NotificationCust/NotificationCust";
import { getPlanTaxableAmount } from "./BillService";
import styles from "./S4_ReviewPlanDetails.module.scss";

type Props = {
  formik: any;
  shipmentPlans: any;
  content: any;
  modemInfo?: any;
};

const T4_ReviewPlan = ({
  formik,
  shipmentPlans,
  content,
  modemInfo,
}: Props) => {
  const { d_title, cp_action_btn, taxes, c_plan_btn } = content?.changeplanData;
  let customerAddress: any = localStorage.getItem("CustomerAddress");
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;

  if (!customerAddress) {
    let shippingAddress = currentdetails?.addresses.filter(
      (value: any) => value.type === "shipping"
    );
    const { address1, address2, city, state, country, zip } =
      shippingAddress[0];
    customerAddress =
      address1 +
      " " +
      address2 +
      " " +
      city +
      " " +
      state +
      " " +
      country +
      " " +
      zip;
  }

  const dispatch = useAppDispatch();
  const {
    planTaxCharges,
    errorUpdateCustomerSource,
    deviceTax,
    coupon,
    errorCoupon,
    activityTypes,
    installTax,
  } = useAppSelector((state: any) => state.checkout);

  let location: any = new URLSearchParams(document.location.search);
  const { dynamicPlansData, dynamicPlansLoading, errorDynamicPlansData } =
    useAppSelector((state: any) => state.planpage);
  const [planDetails, setPlanDetails] = useState<any>(null);
  const [appliedCouponCharges, setAppliedCouponCharges] = useState<number>(0);
  const [deviceTaxAmount, setDeviceTaxAmount] = useState<number>(0);
  const [pickupLoc, setPickupLoc] = useState("");
  const [pickupWindows, setPickupWindows] = useState<any[]>([]);
  const [isInstallSelected, setIsInstallSelected] = useState(false);
  const [installCost, setInstallCost] = useState(0);
  const [installationTax, setInstallationTax] = useState(0);
  const [display, setDisplay] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();

  useEffect(() => {
    let planData = localStorage.getItem("reachSelectedPlanLine");
    if (planData) planData = JSON.parse(planData);
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    if (planDetails) planDetails = JSON.parse(planDetails);
    setPlanDetails(planData ? planData : planDetails?.plan);
    let installationSelected: any = localStorage.getItem("isInstallSelected");
    if (installationSelected)
      setIsInstallSelected(JSON.parse(installationSelected));
    formik.setFieldValue("isInstallSelected", JSON.parse(installationSelected));
    dispatch(
      updateFormValues({
        isInstallSelected: JSON.parse(installationSelected),
      })
    );
  }, []);
  let taxableAmount = getPlanTaxableAmount(planDetails);

  /**
   * To fetch device tax if BYOD is not selected or modem unit price is not 0
   */
  useEffect(() => {
    if (modemInfo?.pickupLocation) {
      let loc = modemInfo?.pickupLocation;
      setPickupLoc(
        loc.street1 + ", " + loc.city + ", " + loc.state + ", " + loc.zip9
      );
    }
    if (modemInfo?.pickupWindow) {
      setPickupWindows(modemInfo?.pickupWindow);
    }
    if (modemInfo?.name !== "BYOD" || modemInfo?.unitPrice !== 0) {
      dispatch(
        getDeviceTaxCharges({
          address: {
            ...formik.values.shippingAddress,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          reachPlanId: planDetails?.name,
          amount: modemInfo?.unitPrice || 0,
        })
      );
    }
  }, [modemInfo]);

  /**
   * To fetch installation cost and tax if BYOD is not selected
   */
  useEffect(() => {
    dispatch(getPlansByAddress(customerAddress));
    dispatch(getActivityTypes());
  }, []);

  useNonInitialEffect(() => {
    setDeviceTaxAmount(deviceTax);
  }, [deviceTax]);

  /**
   * To fetch plan tax charges need shipping address and customer fname and lname is mandatory
   */
  useEffect(() => {
    if (taxableAmount >= 0) {
      dispatch(
        getPlanTaxCharges({
          address: {
            ...formik.values.shippingAddress,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          reachPlanId: planDetails?.name,
          amount: taxableAmount,
        })
      );
    }
  }, [planDetails]);

  useEffect(() => {
    if (EnvConfig?.IS_PROMO_ENABLED && coupon) {
      costCalculation(coupon);
    }
  }, [coupon, planDetails]);
  useEffect(() => {
    if (EnvConfig?.IS_PROMO_ENABLED) {
      const couponDetailsString = localStorage.getItem("couponDetails");
      if (couponDetailsString && !coupon) {
        const couponDetails = JSON.parse(couponDetailsString) as {
          coupon: string;
        };
        dispatch(Coupon({ couponCode: couponDetails.coupon }));
      }
    }
  }, []);

  useNonInitialEffect(() => {
    if (activityTypes?.status === "SUCCESS") {
      setInstallCost(activityTypes?.data?.INSTALATION?.activityCharge);
    }
    dispatch(
      getInstallTaxCharges({
        address: {
          ...formik.values.shippingAddress,
          name: `${formik.values.firstName} ${formik.values.lastName}`,
        },
        reachPlanId: planDetails?.name,
        amount: activityTypes?.data?.INSTALATION?.activityCharge || 0,
      })
    );
  }, [activityTypes]);

  useNonInitialEffect(() => {
    if (installTax?.data?.data?.totalTax > 0 || isInstallSelected) {
      setInstallationTax(installTax);
    }
  }, [installTax, isInstallSelected]);

  useEffect(() => {
    if (isInstallSelected) {
      if (activityTypes?.data?.INSTALATION?.activityCharge) {
        setInstallCost(activityTypes?.data?.INSTALATION?.activityCharge);
      } else {
        setInstallCost(0);
      }
      // setInstallationTax(installTax);
    } else {
      setInstallCost(0);
      setInstallationTax(0);
    }
  }, [isInstallSelected]);

  const openDialog = () => {
    setDisplay(true);
  };
  const closeDialog = () => {
    setDisplay(false);
    setSelectedPlan(undefined);
  };
  // const costCalculation = () => {
  //   let plan: any = planDetails && planDetails;
  //   let basePrice: number = plan && plan?.baseLinePrice;
  //   let totalPrice: number = basePrice;
  //   let discount: number = 0;
  //   return {
  //     discountAmount: discount,
  //     totalCost: totalPrice,
  //     basePrice: basePrice,
  //     totalWithDiscount: totalPrice - discount,
  //   };
  // };
  const costCalculation = (couponData: any) => {
    let plan: any = planDetails && planDetails;
    let basePrice: number = plan && plan?.baseLinePrice;
    let totalPrice: number = basePrice;
    let discount: number = 0;
    localStorage.setItem("couponDetails", JSON.stringify(couponData));
    if (couponData && couponData.discountInDollar && !couponData?.expired) {
      if (totalPrice > couponData.discountInDollar) {
        discount = couponData.discountInDollar;
      } else {
        discount = totalPrice;
      }
    }
    //TODO: Need expired coupon to test along with expired message
    // if (couponData && couponData?.expired) {
    //   NotificationCust({
    //     message: "This coupon code is invalid or has expired.",
    //     type: "danger",
    //     duration: 2000,
    //     id: "CouponFailureNotifier",
    //     showIcon: true,
    //   });
    //   localStorage.removeItem("couponDetails");
    //   discount = 0;
    // }
    setAppliedCouponCharges(discount);
  };
  /**
   * Error notifications handlers
   */

  useEffect(() => {
    if (location?.message && location?.code) {
      NotificationCust({
        message: location?.message,
        type: "danger",
        duration: 2000,
        id: "PaymentGatewayFailure",
        showIcon: true,
      });
    }
  }, [location]);
  useNonInitialEffect(() => {
    if (EnvConfig?.IS_PROMO_ENABLED && errorCoupon) {
      NotificationCust({
        message: errorCoupon,
        type: "danger",
        duration: 2000,
        id: "CouponFailureNotifier",
        showIcon: true,
      });
      localStorage.removeItem("couponDetails");
    }
  }, [errorCoupon]);
  useEffect(() => {
    if (errorUpdateCustomerSource) {
      NotificationCust({
        message: content.user_upd_err,
        type: "danger",
        duration: 2000,
        id: "UserUpdateFailure",
        showIcon: true,
      });
    }
  }, [errorUpdateCustomerSource]);

  const handleCouponRemove = () => {
    setAppliedCouponCharges(0);
    localStorage.removeItem("couponDetails");
    dispatch(resetCoupon());
  };

  const planChargesTaxAmount = planTaxCharges || 0;
  // const totalDueAmount = taxableAmount + planChargesTaxAmount;
  let totalDueAmount =
    planDetails?.baseLinePrice +
    deviceTaxAmount +
    (modemInfo && modemInfo?.unitPrice);
  let totalTax = planChargesTaxAmount + deviceTax;
  if (isInstallSelected && installCost > 0) {
    totalDueAmount =
      totalDueAmount + (installCost + installTax?.data?.data?.totalTax);
    totalTax = totalTax + installTax?.data?.data?.totalTax;
  } else {
    totalDueAmount = totalDueAmount;
    totalTax = totalTax;
  }

  const updatePlan = () => {
    if (selectedPlan) {
      let numberOfLines = planDetails.numberOfLines;
      if (numberOfLines > selectedPlan.maxLines) {
        numberOfLines = selectedPlan.maxLines;
      }
      dispatch(
        updatePlanDetails({
          selectedPlan: selectedPlan,
        })
      );

      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          plan: selectedPlan,
        })
      );
      localStorage.setItem(
        "reachSelectedPlanLine",
        JSON.stringify(selectedPlan)
      );
      setPlanDetails(selectedPlan);
      closeDialog();
    }
  };
  const handlePlanSelection = (newPlan: any) => {
    setSelectedPlan(newPlan);
  };
  const line = () => {
    return (
      <>
        <Box sx={{ border: "0.5px solid #E2E2E2" }}></Box>
      </>
    );
  };
  return (
    <Grid
      container
      className={styles.Review_plan}
      sx={{ p: { sm: "30px 15px 20px", xs: "25px 0" }, gap: "25px" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: { md: "14px", sm: "15px", xs: "10px" },
            px: { xs: 0, md: "61px" },
          }}
          className={styles.review_header}
        >
          {content?.title}
        </Typography>
      </Grid>
      {/* Free Modem Pick up Location */}
      {modemInfo && modemInfo?.name !== "BYOD" && (
        <Box
          sx={{
            width: { xs: "100%", md: "100%", lg: "80%" },
            backgroundColor: "var(--white)",
            boxShadow: "0 0 24px 0 rgba(0,0,0,0.03)",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {!EnvConfig.IS_FSM_ENABLED && (
            <Box sx={{ p: "10px" }}>
              <Typography
                component="div"
                sx={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Typography
                  sx={{
                    color: "var(--primary_brand_color)",
                    fontSize: "20px",
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {content?.pickup_location}
                </Typography>
                <Box
                  component="img"
                  alt={
                    content?.pickup_location_icon?.data?.attributes
                      ?.alternativeText
                  }
                  height="15px"
                  width="auto"
                  src={content?.pickup_location_icon?.data?.attributes?.url}
                />
              </Typography>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Semibold)",
                  mt: "5px",
                }}
              >
                {pickupLoc}
              </Typography>
              {pickupWindows?.map((window: any, i: any) => {
                return (
                  <Typography
                    key={window?.pickupDays}
                    sx={{
                      color: "var(--text_color)",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Semibold)",
                      mt: "5px",
                      display: "block",
                    }}
                  >
                    {window?.pickupDays + " " + window?.pickupTimings}
                  </Typography>
                );
              })}
            </Box>
          )}
          {/* CONGRATS MESSAGE */}
          <Box
            sx={{ backgroundColor: "var(--primary_brand_color)", p: "10px" }}
          >
            <Typography
              component="div"
              sx={{ display: "flex", alignItems: "center", gap: "6px" }}
            >
              <Box
                component="img"
                alt={content?.congrats_icon?.data?.attributes?.alternativeText}
                height="25px"
                width="auto"
                src={content?.congrats_icon?.data?.attributes?.url}
              />
              <Typography
                sx={{
                  color: "var(--white)",
                  fontSize: { xs: "14px", sm: "15px" },
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {content?.congrats_text}
              </Typography>
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          padding: "30px",
          width: { xs: "100%", md: "100%", lg: "70%" },
          backgroundColor: "var(--white)",
          boxShadow: "0 0 24px 0 rgba(0,0,0,0.03)",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {/* Address section  */}
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "var(--font_family_Bold)",
              color: "var(--text_color_8)",
            }}
          >
            {content?.address_title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              color: "var(--text_color_8)",
            }}
          >
            {customerAddress}
          </Typography>
        </Box>
        {line()}
        {/* Plan section  */}
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "var(--font_family_Bold)",
                marginBottom: "12px",
                color: "var(--text_color_8)",
              }}
            >
              {content?.plan_title}
            </Typography>
            <Typography
              onClick={openDialog}
              sx={{
                fontSize: "17px",
                color: "var(--primary_brand_color)",
                fontFamily: "var(--font_family_Medium)",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {c_plan_btn}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "var(--font_family_Medium)",
              fontSize: "16px",
              color: "var(--text_color_8)",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font_family_Semibold)" }}>
              {planDetails?.name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "18px",
              }}
            >
              ${planDetails?.baseLinePrice?.toFixed(2)}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              fontSize: "16px",
              color: "var(--text_color_8)",
            }}
          >
            {content?.plan_desc}
          </Typography>
        </Box>

        <Grid
          item
          container
          xs={12}
          className={styles.change_plan_container}
          sx={{ mb: { md: "9px", sm: "27px", xs: "22px" } }}
        >
          <Dialog
            scroll="body"
            open={display}
            onClose={closeDialog}
            fullWidth
            maxWidth="sm"
          >
            <DialogContent>
              <Close
                onClick={closeDialog}
                style={{
                  color: "var(--balck)",
                  opacity: "0.5",
                  fontSize: "20px",
                  cursor: "pointer",
                  float: "right",
                }}
              />
              <Box
                className="h4"
                lineHeight={1.5}
                textAlign="center"
                fontFamily="var(--font_family_Bold)"
                fontWeight="var(--font_weight_1)"
                sx={{ fontSize: "24px", marginBottom: "20px" }}
              >
                {d_title}
              </Box>

              {dynamicPlansData.length !== 0 ? (
                <PlansContainer
                  content={content}
                  plans={dynamicPlansData}
                  selectedPlan={selectedPlan}
                  activePlanName={planDetails?.name}
                  handlePlanSelection={handlePlanSelection}
                  currentPlanBorder={true}
                />
              ) : (
                <CircularProgress />
              )}
              <Stack
                margin={{ sm: "20px auto", xs: "0 auto" }}
                width={{ sm: "60%", xs: "80%" }}
              >
                <ButtonCust
                  style={{ width: "100%" }}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={updatePlan}
                  disabled={!selectedPlan}
                >
                  {cp_action_btn}
                </ButtonCust>
              </Stack>
            </DialogContent>
          </Dialog>
        </Grid>
        {/* Modem Charges */}
        {modemInfo && modemInfo?.name !== "BYOD" && (
          <>
            {line()}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
                color: "var(--text_color_8)",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color_8)",
                }}
              >
                {content?.modem_charges}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color_8)",
                  fontSize: "18px",
                }}
              >
                {GetCurrencyFixedToTwo(modemInfo?.unitPrice)}
              </Typography>
            </Box>
          </>
        )}

        {line()}
        {/* Plan total section  */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            color: "var(--primary_color)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              color: "var(--primary_color)",
            }}
          >
            {content?.plan_total}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              color: "var(--primary_color)",
              fontSize: "18px",
            }}
          >
            ${planDetails?.baseLinePrice?.toFixed(2)}
          </Typography>
        </Box>
        {line()}
        {/* {INSTALLATION PACKAGE} */}
        {EnvConfig.IS_FSM_ENABLED &&
          modemInfo &&
          modemInfo?.name !== "BYOD" && (
            <>
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Bold) !important",
                  fontSize: "20px",
                  color: "var(--text_color_8)",
                }}
              >
                {content?.installation_title}
                <CustToolTip_T2
                  title={
                    <Typography
                      variant="subtitle2"
                      color={"var(--text_color_8)"}
                      fontSize={"16px"}
                      fontFamily={"var(--font_family_Semibold)"}
                      padding={"10px"}
                      borderTop={"4px solid var(--primary_brand_color)"}
                    >
                      {content?.tooltip_desc}
                    </Typography>
                  }
                  arrow
                >
                  <Box
                    component="img"
                    src={content?.info_icon?.data?.attributes?.url}
                    alt={content?.info_icon?.data?.attributes?.alternativeText}
                    ml={"5px"}
                  />
                </CustToolTip_T2>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: "var(--primary_color)",
                    }}
                    checked={isInstallSelected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsInstallSelected(!isInstallSelected);
                      localStorage.setItem(
                        "isInstallSelected",
                        JSON.stringify(!isInstallSelected)
                      );
                      formik.setFieldValue(
                        "isInstallSelected",
                        !isInstallSelected
                      );
                      dispatch(
                        updateFormValues({
                          isInstallSelected: !isInstallSelected,
                        })
                      );
                    }}
                  />
                }
                label={
                  <>
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        fontSize: "14px",
                      }}
                    >
                      {content?.add_installation} (Cost
                      <Box
                        component={"span"}
                        sx={{
                          color: "var(--primary_color)",
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "14px",
                        }}
                      >
                        &nbsp;
                        {GetCurrencyFixedToTwo(
                          activityTypes?.data?.INSTALATION?.activityCharge
                        )}{" "}
                        +{taxes}
                      </Box>
                      )
                    </Typography>
                  </>
                }
              />

              {isInstallSelected === true && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "16px",
                      color: "var(--text_color_8)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                      }}
                    >
                      {content?.install_charges}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                        fontSize: "18px",
                      }}
                    >
                      {GetCurrencyFixedToTwo(installCost)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "16px",
                      color: "var(--text_color_8)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                      }}
                    >
                      {content?.tax}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color_8)",
                        fontSize: "18px",
                      }}
                    >
                      {installTax?.data?.data?.totalTax
                        ? GetCurrencyFixedToTwo(
                            installTax?.data?.data?.totalTax
                          )
                        : GetCurrencyFixedToTwo(installationTax)}
                    </Typography>
                  </Box>
                </>
              )}
              {!isInstallSelected ? (
                <Box sx={{ p: "10px", backgroundColor: "#f6f4f9" }}>
                  <Typography
                    component="div"
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <Typography
                      sx={{
                        color: "var(--text_color_8)",
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                      }}
                    >
                      {content?.pickup_location}
                    </Typography>
                    <Box
                      component="img"
                      alt={
                        content?.pickup_location_icon?.data?.attributes
                          ?.alternativeText
                      }
                      height="15px"
                      width="auto"
                      src={content?.pickup_location_icon?.data?.attributes?.url}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--text_color_9)",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      mt: "5px",
                      textDecoration: "underline",
                    }}
                  >
                    {pickupLoc}
                  </Typography>
                  {pickupWindows?.map((window: any, i: any) => {
                    return (
                      <Typography
                        key={window?.pickupDays}
                        sx={{
                          color: "var(--text_color)",
                          fontSize: "14px",
                          fontFamily: "var(--font_family_Semibold)",
                          mt: "5px",
                          display: "block",
                        }}
                      >
                        {window?.pickupDays + " " + window?.pickupTimings}
                      </Typography>
                    );
                  })}
                </Box>
              ) : (
                <Box
                  sx={{
                    p: "10px",
                    backgroundColor: "#f6f4f9",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      color: "var(--text_color)",
                      fontSize: "14px",
                    }}
                  >
                    {content?.note}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      color: "var(--text_color)",
                      fontSize: "14px",
                    }}
                  >
                    {content?.note_desc}
                  </Typography>
                </Box>
              )}
              {line()}
            </>
          )}

        {/* Tax section  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            fontFamily: "var(--font_family_Medium)",
          }}
        >
          <Typography sx={{ fontFamily: "var(--font_family_Semibold)" }}>
            {content?.tax}
          </Typography>
          <Typography
            sx={{ fontFamily: "var(--font_family_Semibold)", fontSize: "18px" }}
          >
            {GetCurrencyFixedToTwo(totalTax) || "0.00"}
          </Typography>
        </Box>
        {line()}
        {/* {EnvConfig?.IS_PROMO_ENABLED && (
          <Grid
            item
            xs={12}
            className={styles.offer_code_section}
            sx={{ mt: "14px" }}
          >
            <OfferCodeCmp
              content={content}
              removeCouponCallback={handleCouponRemove}
              appliedCouponAmount={appliedCouponCharges}
            />
          </Grid>
        )} */}
        {/* Total Due section  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              fontSize: "20px",
              color: "var(--text_color_8)",
            }}
          >
            {content?.total}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              fontSize: "20px",
              color: "var(--primary_color)",
            }}
          >
            {totalDueAmount >= 0
              ? GetCurrencyFixedToTwo(totalDueAmount - appliedCouponCharges)
              : GetCurrencyFixedToTwo(0)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
export default T4_ReviewPlan;
