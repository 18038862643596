import TagManager from 'react-gtm-module';

export function gtmTagManager(dataObj: any) {
    try {
        // EnvConfig.IS_GTM_EVENTS_REQUIRED &&
        TagManager.dataLayer({
            dataLayer: {
                ...dataObj,
            },
        });
    } catch (_e) {
        return null;
    }
}
