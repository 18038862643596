export type InitialState = {
  loginNumberSuccess: any;
  errorLoginNumber: any;
  getInTouch: any;
  userData: any;
  resetPasswordData: any;
  errorResetPasswordData: any;
  stateCityByZip: any;
  errorStateCityByZipcode: any;
  getCustomer: any;
  errorGetCustomer: any;
  createCustomer: any;
  errorCreateCustomer: any;
  updateCustomer: any;
  errorUpdateCustomer: any;
  errorPlanTaxCharges: any;
  errorOneTimeSimTaxCharges: any;
  errorOneTimeShippingTaxCharges: any;
  planDetails: IPlanDetails;
  formValues: IFormValues;
  updateCustomerSource: any;
  errorUpdateCustomerSource: any;
  coupon: any;
  errorCoupon: any;
  couponReserve: any;
  errorCouponReserve: any;
  eligibility: any;
  errorEligibility: any;
  isLoaded: boolean;
  isLoadedShippingTaxes: boolean;
  isLoadedPlanTaxCharges: boolean;
  oneTimeSIMTaxCharges: any;
  oneTimeShippingTaxCharges: any;
  planTaxCharges: null | number;
  referrelCoupon: any;
  buttonActionLoader: boolean;
  isRenderLogin: boolean;
  primaryUser: null;
  secondaryUser: null;
  errorGetCouponReserve: any;
  isPrimaryLineEsim: boolean;
  openAutopayConsent: boolean;
  updateMacSuccess: any;
  updateMacFailure: any;
  deviceTax: any;
  errDeviceTax: any;
  activityTypes: any;
  errActivityTypes: any;
  installTax: any;
  errInstallTax: any;
  troubleCallTax: any;
  errTroubleCallTax: any;
};

export interface IPlanDetails {
  numberOfLines: null | number;
  selectedPlan: any;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  shipmentPlan: string;
  isShippingSameAsBilling: true;
  billingAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    zip9: string;
    type: addressType.billing;
  };
  shippingAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    zip9: string;
    type: addressType.shipping;
  };
  autopay: boolean;
}
export interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  type?: addressType.shipping;
}
export enum addressType {
  billing = "billing",
  shipping = "shipping",
}
export enum TaxTypes {
  sim = 1,
  plan = 2,
  device = 3,
  shipment = 23,
  installation = 41,
  troubleCall = 42,
}

export enum SimTypes {
  sim = "Sim",
  eSim = "eSim",
  phySim = "PHYSICAL_SIM",
}
